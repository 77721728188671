export default {
  AT: 'Österreich',
  BE: "Belgien",
  BG: "Bulgarien",
  HR: "Kroatien",
  CY: "Zypern",
  CZ: "Tschechische Republik",
  DK: "Dänemark",
  FI: "Finnland",
  FR: "Frankreich",
  DE: "Deutschland",
  EL: "Griechenland",
  HU: "Ungarn",
  IE: "Irland",
  IT: "Italien",
  LV: "Lettland",
  LT: "Litauen",
  LU: "Luxemburg",
  MT: "Malta",
  NL: "Niederlande",
  PL: "Polen",
  PT: "Portugal",
  RO: 'Rumänien',
  SG: "Singapur",
  SK: "Slowakei",
  SI: 'Slowenien',
  ES: "Spanien",
  SE: "Schweden",
  UK: "Vereinigtes Königreich",
  IS: "Island",
  LI: 'Liechtenstein',
  NO: 'Norwegen',
  CH: 'Schweiz',
};
