/* eslint quotes: ["error", "backtick"] */

export default {
  web_available:` Verfügbar`,
  web_exhausted:` Nicht auf Lager`,
  web_accept_terms_and_privacy_1:` Ich akzeptiere die`,
  web_accept_terms_and_privacy_2:` Nutzungsbedingungen`,
  web_accept_terms_and_privacy_3:` und die`,
  web_accept_terms_and_privacy_4:` Datenschutzrichtlinie`,
  web_about:` Wer sind wir?`,
  web_already_have_account:` Besitzen Sie bereits ein Konto?`,
  web_cancel:` Abbrechen`,
  web_circumference:` Umfang`,
  web_city:` Stadt.`,
  web_comment:` Kommentar`,
  web_company_name:` Firmenname`,
  web_optional:` (fakultativ)`,
  web_confirm:` Bestätigen`,
  web_contact:` Kontakt`,
  web_contact_success:` Wir haben Ihre Kontaktanfrage erhalten und werden uns so schnell wie möglich bei Ihnen melden. Vielen Dank für Ihr Interesse an unseren Produkten!`,
  web_country:` Land.`,
  web_delete:` Löschen`,
  web_email:` E-Mail`,
  web_emc_in_practice:` In der Praxis`,
  web_first_name:` Vorname`,
  web_forgot_password:` Haben Sie Ihr Passwort vergessen?`,
  web_form_required_email_confirm:` E-Mail-Adresse bestätigen`,
  web_form_required_field:` Dieses Feld ist erforderlich`,
  web_gdpr_signup_information:` Mit der Erstellung eines Kontos erkennen Sie an, dass Timbtrack Ihnen Zugang zu einer Version seiner digitalen Serviceleistungen im Bereich der Verwaltung und Klassifizierung forstwirtschaftlicher Daten gewährt.<br/></br>Diese Dienste werden von der Firma I.B.R. S.A. als Eigentümerin der Marke Timbtrack angeboten.<br/></br>Zur korrekten Erbringung dieser Dienste benötigt Timbtrack Ihre persönlichen Daten, um Ihren Zugang freizuschalten, diesen zu sichern und um die Verbindungen zwischen den Nutzern der Timbtrack-Community herzustellen.<br/></br>Ihre Daten werden niemals ohne Ihre ausdrückliche Zustimmung an Dritte verkauft. Wir garantieren, dass diese sensiblen Daten ohne Ihre Zustimmung niemals für andere Zwecke als für die Erbringung der digitalen Dienste von Timbtrack verwendet werden.<br/></br>Dies sind die von uns erfassten persönlichen Daten:`,
  web_gdpr_title:` Über Ihre persönlichen Daten`,
  web_get_in_touch:` Kontaktieren Sie uns`,
  web_height:` Höhe`,
  web_history:` Der Anfang...`,
  web_home:` Home`,
  web_i_agree:` Ich akzeptiere`,
  web_included:` Inklusive`,
  web_information:` Informationen`,
  web_last_name:` Name`,
  web_learn_more:` MEHR ERFAHREN`,
  web_learn_more_lower:` Mehr erfahren`,
  web_login:` Verbindung`,
  web_message:` Nachricht.`,
  web_mission:` Unsere Mission: Der digitale Wald.`,
  web_next:` Weiter`,
  web_no:` Nein`,
  web_password:` Passwort`,
  web_payment:` Zahlung`,
  web_payment_desc:` Dankeschön! Ihre Informationen wurden gespeichert. Sie können nun Ihre Bestellung abschließen.`,
  web_payment_failure_list_1:` Gehen Sie zu <a href=https`,
  web_payment_failure_list_2:` Geben Sie Ihre Login- und Passwortinformationen ein.`,
  web_payment_failure_list_3:` Klicken Sie auf den Link <b>Dashboard</b> im Popup.`,
  web_payment_failure_list_4:` Klicken Sie auf <b>My Information</b>.`,
  web_payment_failure_list_5:` Unter <b>Credit Card</b> finden Sie eine Option zum Ändern der Zahlungsinformationen.`,
  web_payment_failure_part_1:` Hallo<br/><br/>Ihr Finanzinstitut hat eine Bezahlung über Ihre Karte<br/>%s abgelehnt.`,
  web_payment_failure_part_2:` Wenn dieses Problem nicht behoben wird, beeinträchtigt dies die Timbtrack-Dienste.`,
  web_payment_failure_part_3:` Um Ihre Zahlungsinformationen jetzt zu aktualisieren, gehen Sie bitte wie folgt vor:`,
  web_payment_failure_part_4:` Ändern Sie diese Informationen so schnell wie möglich, um einen ungestörten Service zu gewährleisten.`,
  web_payment_failure_part_5:` Das Timbtrack-Team`,
  web_payment_failure_title_1:` Bezahlversuch abgelehnt`,
  web_payment_subscription_desc:` Danke wir haben alle Informationen, die wir brauchen! Sie können jetzt die Schaltfläche unten verwenden, um zu bezahlen und Ihr Abonnement zu bestätigen.`,
  web_pending:` Warten`,
  web_phone:` Telefon`,
  web_phone_number:` Telefonnummer`,
  web_platform:` Plattform`,
  web_platform_sold_out:` Erschöpft`,
  web_press:` Presse`,
  web_press_releases:` Pressespiegel`,
  web_product:` Produkt`,
  web_product_preorder:` Vorbestellung`,
  web_product_preordering:` Vorbestellung`,
  web_products:` Produkte`,
  web_send:` Senden`,
  web_sign_up:` SICH REGISTRIEREN`,
  web_software_month:` Monat`,
  web_step:` Schritt`,
  web_step_1:` Anmelden`,
  web_step_2:` Produkte`,
  web_step_3:` Adressen.`,
  web_step_4:` Zahlung`,
  web_step_5:` Bestätigung`,
  web_step_1_billing:` Schritt 1`,
  web_step_1_shipping_address:` Zustelladresse`,
  web_step_1_billing_address:` Rechnungsanschrift`,
  web_step_1_same_to_billing_address:` Identisch mit der Rechnungsanschrift`,
  web_step_1_email_info:` Um Ihnen die Rechnung zu Ihrer Bestellung zu schicken`,
  web_step_1_save_address:` Adresse speichern`,
  web_step_2_payment:` Schritt 2`,
  web_step_2_payment_method:` Zahlungsart`,
  web_step_2_payment_method_credit_card:` Kreditkarte`,
  web_step_2_payment_method_immediate_bank:` Banküberweisung`,
  web_step_2_payment_method_paypal:` Paypal`,
  web_step_2_payment_method_maestro:` Maestro`,
  web_step_2_payment_add_card:` Fügen Sie eine neue Karte hinzu`,
  web_step_2_payment_validity:` Gültig bis`,
  web_step_2_delivery:` Auslieferung`,
  web_step_2_vat:` MWST.`,
  web_step_2_order:` Bestellung`,
  web_software:` Plattform`,
  web_step_3_validation_pay_and_validate:` Kaufen und bestätigen`,
  web_step_4_access_plateforme:` Gehen Sie zur Timbtrack-Plattform`,
  web_step_4_done_thank_you:` Vielen Dank für Ihre Bestellung!`,
  web_step_4_done_email:` Sie erhalten eine Bestätigungs-E-Mail an folgende Adresse:`,
  web_street:` Straße und Hausnummer`,
  web_software_order:` Bestellung`,
  web_software_area:` Berechnung der Fläche (ha)`,
  web_software_area_description:` Berechnung der Fläche`,
  web_software_cuts:` Identifikation von anstehenden Schnitten`,
  web_software_cuts_description:` Identifikation von anstehenden Schnitten`,
  web_software_lots:` Bewertung der zum Verkauf stehenden Lose`,
  web_software_lots_description:` Bewertung der zum Verkauf stehenden Lose`,
  web_software_stats:` Statistik`,
  web_software_stats_description:` Forstwirtschaftliche Statistik`,
  web_software_history:` Chronik der Daten`,
  web_software_history_description:` Chronik der Daten`,
  web_software_gps:` GPS-Koordinaten`,
  web_software_circumference:` Umfang`,
  web_software_height:` Höhe`,
  web_software_conicity:` Konizität`,
  web_software_specie:` Arten`,
  web_software_diameter:` Durchmesser`,
  web_software_quality:` Qualität`,
  web_software_volume:` Volumen`,
  web_footer_head_office:` Hauptsitz`,
  web_footer_belgium:` Belgien`,
  web_footer_vat:` MWST.`,
  web_footer_office:` Büro`,
  web_team:` Der Teamtrack`,
  web_team_team:` Das Team`,
  web_team_ca:` Der Verwaltungsrat`,
  web_timbtrack_office:` Büros von Timbtrack`,
  web_timbtrack_office_address:` Rue de Néverlée 1 1. Etage`,
  web_total:` Gesamt.`,
  web_vat:` MWST.`,
  web_vat_double_check:` Bitte überprüfen Sie nochmals Ihre MwSt.-Nummer`,
  web_vat_excluded:` Ohne MwSt.`,
  web_wait:` Bitte warten Sie`,
  web_watch_the_movie:` Video ansehen`,
  web_zip:` Postleitzahl`,
  web_technical_information:` Technische Informationen`,
  web_inventory:` Inventar`,
  web_reset_password:` Passwort zurücksetzen`,
  web_save:` Speichern`,
  web_terms_and_conditions:` Allgemeine Geschäftsbedingungen`,
  web_sold_out_product:` Nicht auf Lager`,
  web_sold_out_product_subtitle:` Wir werden Sie per E-Mail kontaktieren, sobald dieser Artikel wieder auf Lager ist`,
  web_forest:` Forstwirtschaft`,
  web_agricultural:` Landwirtschaft`,
  web_protected_area:` Geschützter Bereich`,
  web_secure_payment:` Gesicherte Zahlung`,
  web_delivery_emc:` Lieferung innerhalb von drei Monaten`,
  web_promo_code:` Geben Sie Ihren Promo-Code ein (fakultativ)`,
  web_validate:` Bestätigen`,
  web_jobs:` Jobs`,
  web_full_time:` Vollzeit`,
  web_rhisnes:` Rhisnes (Namur) - Belgien`,
  web_job_description:` Funktionsbeschreibung`,
  web_spontaneous_application:` Spontane Bewerbung`,
  web_spontaneous_application_description:` Timbtrack wächst und ist ständig auf der Suche nach Talenten, die bereit sind, einen Beitrag zur Forstwirtschaft (und ländlichen Vermögenswerten) zu leisten. Zögern Sie nicht, schicken Sie uns Ihren Lebenslauf.`,
  web_our_events:` Unsere Events`,
  web_payment_sign_up:` Ich bin neu hier`,
  web_management_platform:` Abonnement der Verwaltungsplattform`,
  web_measuring_tools:` Messwerkzeuge`,
  web_validate_plan:` Ich bestätige den Plan`,
  web_plan:` Abonnement.`,
  web_new_plan:` Neuer Plan`,
  web_do_not_change_subscription:` Ich möchte mein Abonnement nicht ändern`,
  web_do_not_change_tools:` Ich benötige keine Messwerkzeuge`,
  web_payment_subscription_platform:` Zahlung des Abonnements für die Plattform`,
  web_payment_tools:` Zahlung für Produkte`,
  web_payment_trunk_mail:` HINWEIS`,
  web_payment_credit_card:` Zahlung per Kreditkarte und Banküberweisung`,
  web_password_reset_sent:` Wir haben eine E-Mail an die folgende E-Mail-Adresse gesendet:`,
  web_below_current_plan:` Sie müssen einen unserer Mitarbeiter kontaktieren, um den Plan zurückzustufen.`,
  web_current_plan:` Aktueller Plan`,
  web_per_month:` Pro Monat`,
  web_one_year_contract:` Ein-Jahres-Vertrag.`,
  web_card_error:` Es ist ein Fehler mit Ihrer Kreditkarte eingetreten`,
  web_add_credit_card:` Eine Kreditkarte hinzufügen.`,
  web_google:` Timbtrack | Messwerkzeuge und Forstmanagement-Plattform`,
  web_google_moto:` Mit seinen vernetzten Baumvermessungswerkzeugen und seiner Forstmanagement-Plattform entwickelt Timbtrack innovative und effiziente Lösungen für den Forstsektor, die eine Zeitersparnis von 25 bis 70 % bei Vermessungs- Inventur- und Verwaltungsarbeiten ermöglichen.`,
  web_uvp_timbtrack:` Messwerkzeuge & Forstmanagement-Plattform`,
  web_moto_timbtrack:` Innovation, Einfachheit, Zeitersparnis`,
  web_moto2_timbtrack:` Vermessen heißt seinen Wald zu verstehen`,
  web_moto_description_timbtrack:` Timbtrack verbessert und vereinfacht die Waldbewirtschaftung und reduziert den Zeit- und Kostenaufwand für die Vermessung und Entwicklung Ihres Waldes ganz erheblich.`,
  web_uvp_emc:` Das vernetzte Forstmaßband`,
  web_moto_emc:` Perfekt für Waldbesitzer, Bewirtschafter und Techniker. Das EMC bietet eine schnelle und exakte, geolokalisierte Digitalvermessung der Bäume. Damit beschleunigt und vereinfacht das Tool die Waldinventur und die Vermessung des Bestands. In Verbindung mit der Plattform erbringt das EMC eine Ersparnis an Messzeit und ein Plus an Datensicherheit, was nachhaltige Entscheidungen in der Forstwirtschaft erleichtert.`,
  web_uvp_tpm:` Tree Parameter Measuring`,
  web_uvp2_tpm:` Das fortschrittlichste Forstmessgerät auf dem Markt`,
  web_moto_tpm:` Das TPM wurde für den professionellen Einsatz entwickelt und ist derzeit das fortschrittlichste Baummessgerät auf dem Markt. Dank modernster Laser- Bildgebungs- und Geolokalisierungstechnologien misst das TPM die waldbaulichen Parameter, die für eine präzise und nachhaltige Waldbewirtschaftung unerlässlich sind (Höhe, Umfang, Qualität, GPS-Position usw.). Alle erfassten Daten werden automatisch von der Timbtrack-Managementplattform verarbeitet.`,
  web_uvp_platform:` Plattform`,
  web_uvp2_platform:` Managementplattform für Forstwirtschaft und ländliche Vermögenswerte`,
  web_moto_platform:` Die intuitive und effizienzorientierte Plattform ermöglicht es Ihnen, Ihre Felddaten mit drei Klicks zu importieren, sie automatisch zu analysieren und chronologisch zu klassifizieren. Die Informationen, die Sie für die Verwaltung Ihrer forstwirtschaftlichen und ländlichen Güter benötigen, waren noch nie so leicht zugänglich und einfach zu kommunizieren.  Unabhängig nutzbar, ist sie die natürliche Fortsetzung unserer Messwerkzeuge.`,
  web_gps_emc:` Automatische GPS-Punkterkennung für jeden vermessenen Baum`,
  web_circumference_emc:` Messen Sie schnell und genau den Umfang Ihrer Bäume bis zu 6 m. Umrüstbar auf Durchmesser auf der Plattform`,
  web_height_emc:` Messen Sie die Höhe Ihrer Bäume standardmäßig pro Los oder einzeln`,
  web_conicity_emc:` Messen Sie die Standardkonizitätsrate standardmäßig pro Los oder einzeln`,
  web_volume_emc:` Volumen automatisch auf der Plattform berechnet`,
  web_species_emc:` Erkennen Sie die Standard-Baumart Unterart oder Qualität pro Los oder einzeln`,
  web_numerotation_emc:` Automatische Nummerierung jedes vermessenen Baumes`,
  web_numerotation:` Nummerierung`,
  web_seconds_measured_tree_emc:` 8 Sekunden pro vermessenem Baum`,
  web_time_saving_field_emc:` Zeitersparnis vor Ort`,
  web_time_saving_office_emc:` Zeitersparnis im Büro`,
  web_autonomy_emc:` 1 Tag Arbeit`,
  web_warranty_emc:` 2 Jahre`,
  web_weight_emc:` 300g`,
  web_moto2_platform:` MIT DER TIMBTRACK PLATTFORM VERNETZT`,
  web_moto3_platform:` Die Timbtrack-Plattform bietet Ihnen einen globalen Überblick über Ihren gesamten Wald. Dank der verschiedenen Timbtrack-Module ist die Verwaltung der Forstdaten automatisiert und sofort verfügbar, was eine erhebliche Zeitersparnis bedeutet.`,
  web_gps_tpm:` Automatische GPS-Punkterkennung für jeden vermessenen Baum`,
  web_circumference_tpm:` Schnelles und genaues Messen der Baumdurchmesser, diese können auf der Timbtrack-Plattform in Umfänge umgerechnet werden`,
  web_height_tpm:` Messen Sie die genaue Höhe Ihrer Bäume mit einem Klick`,
  web_conicity_tpm:` Lesen Sie die Konizitätsrate mit einem Klick ab`,
  web_species_tpm:` Lesen Sie die Baumart mit einem Klick ab`,
  web_volume_tpm:` Automatische Berechnung des Baumvolumens vor Ort`,
  web_quality_tpm:` Lesen Sie due Qualität des Baumes ab`,
  web_numerotation_tpm:` Automatische Nummerierung jedes vermessenen Baumes`,
  web_seconds_measured_tree_tpm:` 3 Sekunden pro vermessenem Baum`,
  web_time_saving_field_tpm:` Zeitersparnis vor Ort`,
  web_time_saving_office_tpm:` Zeitersparnis im Büro`,
  web_autonomy_tpm:` 1 Tag Arbeit`,
  web_warranty_tpm:` 2 Jahre`,
  web_weight_tpm:` 770g`,
  web_safe_about:` Mit seinen Lösungen verpflichtet sich Timbtrack, Ihnen ein SICHERES Produkt zu bieten`,
  web_safe_security_about:` Optimale Sicherheit bei der Erfassung und Klassifizierung Ihrer Daten`,
  web_safe_security:` Sicherheit`,
  web_safe_audacity_about:` Eine permanente Innovation, die den Zugang zu Spitzentechnologien ermöglicht`,
  web_safe_audacity:` Fortschrittlich`,
  web_safe_faithful_about:` Robuste langfristige Lösungen`,
  web_safe_faithful:` Verlässlichkeit`,
  web_safe_evolution_about:` Kontinuierliche Entwicklung, die Ihre Bedürfnisse vor Ort erfüllt`,
  web_safe_evolution:` Evolution`,
  web_history_timbtrack:` Mit seiner unternehmerischen Erfahrung in Afrika beschloss Quentin d‘Huart Gründer und Anteilseigner des Unternehmens im Jahr 2013 den Forstmarkt anzugehen. Als er seinen Vater beobachtete, wie er im Wald Messungen vornahm und viel Zeit vor dem Computer verbrachte, erkannte er die Notwendigkeit, innovative Technologien in diesem traditionellen Sektor einzubringen. In Gesprächen mit Forstexperten und Interessenvertretern des Holzsektors wurde ihm schnell klar, dass die moderne Technologie ihnen dabei helfen könnte, sich auf ihre Leidenschaft, den Wald, zu konzentrieren, und zwar ohne die ganze administrative Überlastung, die diese Arbeit mit sich bringt. Er umgab sich mit einem Team belgischer Ingenieure und Entwickler, um dieses neue Projekt auf den Weg zu bringen. Bald wurde aus den ersten Zeichnungen ein echter Prototyp. Das TPM war geboren! Um eine komplette Produktlinie anbieten zu können, setzten die Timbtrack-Teams ihre Entwicklung fort und schufen den EMC den Forest Connected Meter.  Gleichzeitig wurde die Forstmanagement-Plattform Timbtrack geschaffen, um eine genaue Überwachung und nachhaltige Bewirtschaftung der ländlichen Güter zu ermöglichen. Diese Software ist die natürliche Erweiterung unserer Werkzeuge. Heute positioniert sich Timbtrack als belgischer Marktführer für digitale Forstmanagement-Tools`,
  web_parcel_platform:` Los-orientiert`,
  web_area_platform:` Berechnung der Flächen (ha)`,
  web_history_platform:` Chronik der Daten`,
  web_batches_sales_platform:` Bewertung von zum Verkauf stehenden Losen`,
  web_inventory_cubing_platform:` Inventarisierung und automatische Vermessung`,
  web_stats_platform:` Waldbau- und Finanzstatistiken`,
  web_wood_cut_platform:` Identifikation von anstehenden Schnitten`,
  web_bio_dead_trees_platform:` Baum von biologischem Interesse und Tod.`,
  web_comment_platform:` Verwaltungskommentar`,
  web_modern_interface_platform:` EINE MODERNE SCHNITTSTELLE`,
  web_modern_interface_moto_platform:` Unsere intuitive, flüssig arbeitende Plattform optimiert die professionelle Verwaltung Ihrer forstwirtschaftlichen Daten. Die Ära der manuellen Eingabe ist vorbei!`,
  web_automatic_function_platform:` AUTOMATISCHE KFUNKTIONEN`,
  web_automatic_function_moto_platform:` Unsere Plattform erstellt automatisch Ihre Bestandsstatistiken. Weniger Zeitaufwand und verbesserte Übersicht bei der Analyse Ihrer Daten`,
  web_simplified_communication_platform:` VEREINFACHTE KOMMUNIKATION`,
  web_simplified_communication_moto_platform:` Dank der Sharing-Tools ist es einfacher denn je, Ihre Informationen an die richtigen Personen weiterzuleiten.`,
  web_digital_safe_platform:` EIN DIGITALER TRESOR`,
  web_digital_safe_moto_platform:` Gehostet in unserer gesicherten Cloud können die Daten von jedem Browser aus sicher aufgerufen werden.`,
  web_or:` oder`,
  web_account_already_have:` Sie haben bereits ein Konto mit dieser Adresse`,
  web_loading_information:` Ihre Informationen werden geladen …`,
  web_yearly:` Jährlich`,
  web_monthly:` Monatlich`,
  web_save_percentage:` -10 %`,
  web_year:` Jahr`,
  web_per_year:` Pro Jahr`,
  web_immediate_payment:` Sofortige Zahlung`,
  web_access_platform:` Zugang zur Plattform`,
  web_your_pack:` Ihr Paket`,
  web_info_sent_mail_bank_transfer:` Die Informationen werden Ihnen per E-Mail zugesandt, sie können Sie auch bei der Bestätigung ablesen.`,
  web_platform_contract:` Zugang zur Plattform`,
  web_payment_by_month:` Zahlung pro Monat möglich`,
  web_year_2:` Jahr`,
  web_connect_to_platform:` Sich bei der Plattform anmelden`,
  web_already_own_platform:` Ich besitze bereits die Plattform`,
  web_single_payment:` Zahlung pro Einheit`,
  web_for_year:` für ein Jahr`,
  web_subscription_platform:` Abonnement der Plattform`,
  web_buy_1_emc_20_ha:` Sie müssen mindestens 1 EMC und 20 ha kaufen. Wenn Sie die Plattform bereits besitzen, klicken Sie bitte auf den Link „Ich habe die Plattform bereits“.`,
  web_confirm_buy_agree_1:` Indem Sie auf Kaufen und Bestätigen klicken, willigen Sie darin ein, dass `,
  web_confirm_buy_agree_2:` Um einen unterbrechungsfreien Service zu gewährleisten, wird Ihr Abonnement automatisch über den Zahlungsbetrag verlängert:`,
  web_confirm_buy_agree_2_tva:` (ggf. zuzüglich MwSt.)`,
  web_confirm_buy_agree_3:` mit Ihrer nächsten fälligen Zahlung am`,
  web_confirm_buy_agree_4_year:` Sie ermächtigen uns, Ihre Karte jedes Jahr mit diesem Betrag zu belasten`,
  web_confirm_buy_agree_4_month:` Sie ermächtigen uns, monatliche Zahlungen über Ihre Karte abzubuchen, um den Gesamtbetrag der jährlichen automatischen Verlängerung zu erreichen.`,
  web_confirm_buy_agree_4_no_vat_europe:` Sie können Ihr Abonnement innerhalb von 15 Tagen nach der ersten Zahlung kündigen`,
  web_confirm_buy_agree_5:` Sie können die Verlängerung jederzeit über Ihr Profil auf der Plattform (app.timbtrack.com) oder durch Kontaktaufnahme mit uns deaktivieren`,
  web_confirm_buy_agree_6:` Sie können es innerhalb von 14 Tagen vor Vertragsende ohne Kontaktaufnahme über Ihr Konto kündigen. Sie akzeptieren auch unsere`,
  web_confirm_buy_agree_7:` und bestätigen, dass Sie unsere XXXXX gelesen und verstanden haben`,
  web_confirm_buy_title:` Überprüfung und Bestätigung Ihrer Bestellung`,
  web_transfer_information:` Nachfolgend finden Sie die notwendigen Informationen für Ihre Überweisung. Der Liefervorgang wird ausgelöst, sobald wir den korrekten Zahlungseingang bestätigt haben.`,
  web_app_baseline:` Forstwirtschaft und Verwaltung ländlicher Vermögenswerte`,
  web_visors_caregivers:` Integraler Spritzschutz!`,
  web_visors_paragraph_1:` Timbtrack hat ein Visier entwickelt, das den Hygienevorschriften entspricht, die während der Covid-19-Krise eingeführt wurden.  Diese Vollgesichtsschutzmaske wurde entwickelt, um berufstätige Menschen während der Covid 19-Krise zu schützen.`,
  web_visors_paragraph_2:` Unterstützen Sie das Projekt, Ihre Spenden sind wichtig!`,
  web_visors_visit_site:` Besuchen Sie die Seite`,
  web_visors_make_donation:` Spenden`,
  rules_first_name_required:` Der Vorname ist erforderlich`,
  rules_last_name_required:` Der Name ist obligatorisch`,
  rules_phone_number_required:` Die Rufnummer ist nicht im gültigen internationalen Format`,
  rules_email_required:` Ungültige E-Mail-Adresse`,
  rules_email_required_confirm:` Bestätigen, dass die E-Mail-Adresse erforderlich ist`,
  rules_password_required:` Ein neues Passwort ist erforderlich`,
  rules_password_not_secure:` Das Passwort ist nicht sicher genug (8 Zeichen mit Zahlen und Großbuchstaben eingeben)`,
  rules_confirm_new_password:` Bestätigen Sie, dass das neue Passwort erforderlich ist`,
  web_mail_request:` Wir werden Sie per E-Mail bezüglich Ihrer Anfrage kontaktieren.`,
  web_sawmill: `Sägewerk `,
  web_testing: `Im Test...`,
  web_app_soon:`Das Messen Ihrer Bäume mit Ihrem Telefon ist bald möglich!`,
  web_app_soon_store_play:`Demnächst im App Store und bei Google Play erhältlich!`,
};
