import 'babel-polyfill';

import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VTooltip from 'v-tooltip';

import './style.scss';

import * as VueGoogleMaps from 'vue2-google-maps';

import { noRoboto } from './utils/map';
import { en, fr, pt, nl, de } from './utils/lang';
import { GOOGLE_MAP_API_KEY } from './services';

import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Timbtrack from './Timbtrack.vue';

Vue.use(VueRouter);

Vue.use(Vuetify, {
  iconfont: 'fa',
  theme: {
    primary: '#4180ff',
    secondary: '#b0bec5',
    accent: '#8c9eff',
    error: '#b71c1c',
  },
});

noRoboto();
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: 'places,drawing,geometry',
  },
});

Vue.use(VueI18n);
Vue.use(VTooltip);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
    pt,
    nl,
    de,
  }, // set locale messages
});

new Vue({ // eslint-disable-line no-new
  i18n,
  el: '#timbtrack',
  render: h => h(Timbtrack),
});
