const env = process.env.NODE_ENV;

const appUrls = {
  development: 'http://app.timbtrack.local:3003',
  staging: 'https://staging.app.timbtrack.com',
  production: 'https://app.timbtrack.com',
};

const apiUrls = {
  development: 'http://api.timbtrack.local:8000',
  staging: 'https://staging.api.timbtrack.com',
  production: 'https://api.timbtrack.com',
};

const stripeKeys = {
  development: 'pk_test_thiJ8N8ZzlPDroDRQPzpLypA',
  staging: 'pk_test_thiJ8N8ZzlPDroDRQPzpLypA',
  production: 'pk_live_cf8Lrso1AW9DuQIMdFIKSVsc',
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyA2kpkuHuD3kg60xRUMe2b-Q1yfIsDbgbw';

export const apiUrl = apiUrls[env];
export const appUrl = appUrls[env];
export const stripeKey = stripeKeys[env];
var stripeInstance = null;
export const getStripeInstance = () => {
  if (!stripeInstance) {
    stripeInstance = Stripe(stripeKey);
  }

  return stripeInstance;
};

export const VAT = 21;

export const CREDENTIALS = {
  LOGIN: `${apiUrl}/v1/auth/login`,
  LOGOUT: `${apiUrl}/v1/auth/logout`,
  ASK_RESET: `${apiUrl}/v1/auth/ask_reset_password`,
  RESET: `${apiUrl}/v1/auth/reset_password`,
  FORGOT: `${apiUrl}/v1/auth/forgot`,
  CHECK: `${apiUrl}/v1/auth/check_existing`,
};

export const PROFILE = {
  GET: `${apiUrl}/v1/profile`,
};

export const ORDERS = {
  GET: `${apiUrl}/v1/orders`,
  POST: `${apiUrl}/v1/orders`,
  PAY: `${apiUrl}/v1/order_pay`,
  VALIDATE: `${apiUrl}/v1/orders/validate`,
  SETUP_INTENT: `${apiUrl}/v1/orders/setup_intent`,
  SETUP_PAYMENT_METHOD: `${apiUrl}/v1/orders/setup_payment_method`,
  DISCOUNT_VALIDATE: `${apiUrl}/v1/discountsByCode`,
  GET_METHODS: `${apiUrl}/v1/stripe/payment_methods`,
};

export const UPS_SHIPPING = {
  GET: `${apiUrl}/v1/ups/rate`,
};

export const SUBSCRIPTION = {
  POST: `${apiUrl}/v1/subscriptions`,
  UPGRADE: `${apiUrl}/v1/subscriptions/upgrade`,
};

export const PRICINGS = {
  POST: `${apiUrl}/v1/priceCalculation`,
};

export const VAT_CHECKER = {
  GET: `${apiUrl}/v1/vat/check`,
};
