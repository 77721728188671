/* eslint quotes: ["error", "backtick"] */

export default {
  web_available: `Disponível`,
  web_exhausted: `Esgotado`,
  web_accept_terms_and_privacy_1: `Aceito os`,
  web_accept_terms_and_privacy_2: `Termos de serviço`,
  web_accept_terms_and_privacy_3: `e a`,
  web_accept_terms_and_privacy_4: `Política de Privacidade`,
  web_about: `Sobre`,
  web_already_have_account: `Já tem conta?`,
  web_cancel: `Cancelar`,
  web_circumference: `Perímetro`,
  web_city: `Cidade`,
  web_comment: `Comentário`,
  web_company_name: `Nome da empresa`,
  web_optional: ` (opcional)`,
  web_confirm: `Confirmar`,
  web_contact: `Contacto`,
  web_contact_success: `Recebemos com sucesso um pedido de contacto and voltaremos ao contacto brevemente. Obrigado por mostrar interesse nos nossos produtos!`,
  web_country: `País`,
  web_delete: `Apagar`,
  web_email: `E-mail`,
  web_emc_in_practice: `Na prática`,
  web_first_name: `Primeiro nome`,
  web_forgot_password: `Esqueceu-se da palavra-passe?`,
  web_form_required_email_confirm: `Confirme o seu e-mail`,
  web_form_required_field: `Este item é obrigatório`,
  web_gdpr_signup_information: `Ao criar uma conta, você reconhece que o Timbtrack fornece acesso a uma versão de seus serviços digitais no gerenciamento e classificação de dados da floresta.</br></br>Esses serviços são oferecidos pela I.B.R. S.A., proprietária da marca "Timbtrack". <br/></br>Para operar esses serviços corretamente, o Timbtrack precisa usar seus dados pessoais para criar seu acesso, torná-lo seguro e criar links entre usuários na comunidade do Timbtrack. <br/></br>Seus dados nunca serão vendidos a terceiros sem o seu consentimento explícito. Garantimos que, sem a sua permissão, essas informações confidenciais nunca serão usadas por outros motivos que não os serviços digitais da Timbtrack. <br/></br>As informações pessoais que coletamos são as seguintes: <b>sobrenome</b>, <b>nome</b>, <b>endereço de e-mail</b>, <b>número de telefone</b> e <b>endereço(s) da sua propriedade</b><br/></br>Ao dar seu consentimento, você recebe um acesso pessoal e protegido. É acessível através do "Login" na página da web www.timbtrack.com com seu nome de usuário e senha, conhecidos apenas por você. Você pode, a qualquer momento, consultar seus dados pessoais e / ou editar a exclusão de dados, pois parte de um contrato é regida por obrigações. A ser realizado pela IBR S.A. e uso.<br/></br>Aviso: recomendamos que você mantenha esse acesso confidencial. <br/></br>Estamos à sua disposição para qualquer dúvida que possa ter no endereço de e-mail info@timbtrack.com. <br/></br>A equipe Timbtrack <br/>`,
  web_gdpr_title: `Sobre a sua informação pessoal`,
  web_get_in_touch: `Entre em contacto`,
  web_height: `Altura`,
  web_history: `O começo ...`,
  web_home: `Casa`,
  web_i_agree: `Eu aceito`,
  web_included: `Incluído`,
  web_information: `Informação`,
  web_last_name: `Último nome`,
  web_learn_more: `SAIBA MAIS`,
  web_learn_more_lower: `Saiba Mais`,
  web_login: `Conexão`,
  web_message: `Mensagem`,
  web_mission: `Nossa missão, a floresta digital.`,
  web_next: `Seguinte`,
  web_no: `Não`,
  web_password: `Palavra-passe`,
  web_payment: `Pagamento`,
  web_payment_desc: `Obrigado! Toda a informação necessária foi enviada. Pode agora completar o seu pedido.`,
  web_payment_failure_list_1: `Vá a <a href="https://www.timbtrack.com">https://www.timbtrack.com</a> e clique em "<b>Login</b>",`,
  web_payment_failure_list_2: `Insira o seu username e password`,
  web_payment_failure_list_3: `Carregue no link "<b>Dashboard</b>" no pop-up.`,
  web_payment_failure_list_4: `Carregue em "<b>My infromation</b>",`,
  web_payment_failure_list_5: `Debaixo do título do "<b>Credit card</b>" irá encontrar uma opção para modificar o seu método de pagamento.`,
  web_payment_failure_part_1: `Olá,<br/><br/>O seu banco não aceitou o pagamento efectuado com o cartão abaixo:<br/>%s on the %s.`,
  web_payment_failure_part_2: `Se o problema persistir, os serviços da Timbtrack vão ser afetados.`,
  web_payment_failure_part_3: `Para atualizar a informação do seu pagamento, pode avançar como:`,
  web_payment_failure_part_4: `Por favor altere esta informação o mais rápido possível, para que possa continuar a desfrutar dos serviços da Timbtrack sem interrupções.`,
  web_payment_failure_part_5: `A equipa da Timbtrack.`,
  web_payment_failure_part_6: `<b>Por favor não responda para este e-mail!</b><br/>Se tiver alguma dúvida acerca da sua fatura, por favor entre em contacto connosco para info@timbtrack.com com o número da sua encomenda como referência.<br/>`,
  web_payment_failure_title_1: `Pagamento rejeitado`,
  web_payment_subscription_desc: `Obrigado, temos toda a informação que precisamos! Pode agora carregar no botão abaixo para pagar e finalizar a sua subscrição:`,
  web_pending: `Pendente`,
  web_phone: `Telefone`,
  web_phone_number: `Número de telefone`,
  web_platform: `Plataforma`,
  web_platform_sold_out: `Esgotado`,
  web_press: `Imprensa`,
  web_press_releases: `Artigos de imprensa`,
  web_product: `Produto`,
  web_product_preorder: `Pré-venda`,
  web_product_preordering: `Pré-ordem`,
  web_products: `Produtos`,
  web_send: `Enviar`,
  web_sign_up: `Registar`,
  web_software_month: `Mês`,
  web_step: `Passo`,
  web_step_1: `Entrar`,
  web_step_2: `Produtos`,
  web_step_3: `Endereços`,
  web_step_4: `Pagamento`,
  web_step_5: `Validação`,
  web_step_1_billing: `1º Passo: Morada de cobrança`,
  web_step_1_shipping_address: `Endereço de entrega`,
  web_step_1_billing_address: `Endereço de cobrança`,
  web_step_1_same_to_billing_address: `O mesmo que endereço de entrega`,
  web_step_1_email_info: `Para enviar a fatura do seu pedido`,
  web_step_1_save_address: `Salvar endereço`,
  web_step_2_payment: `2º Passo: Pagamento`,
  web_step_2_payment_method: `Método de pagamento`,
  web_step_2_payment_method_credit_card: `Cartão de crédito`,
  web_step_2_payment_method_immediate_bank: `Transferência Bancária`,
  web_step_2_payment_method_paypal: `Paypal`,
  web_step_2_payment_method_maestro: `Maestro`,
  web_step_2_payment_add_card: `Adicione um novo cartão`,
  web_step_2_payment_validity: `válido até`,
  web_step_2_delivery: `Entrega`,
  web_step_2_vat: `IVA`,
  web_step_2_order: `ordem`,
  web_software: `Plataforma`,
  web_step_3_validation_pay_and_validate: `Compre e valide`,
  web_step_4_access_plateforme: `Vá para a plataforma Timbtrack`,
  web_step_4_done_thank_you: `Muito obrigado pela sua encomenda!`,
  web_step_4_done_email: `Você receberá um e-mail de confirmação no seguinte endereço:`,
  web_street: `Rua e número`,
  web_software_order: `Ordem`,
  web_software_area: `Cálculo da área (ha)`,
  web_software_area_description: `Cálculo da área`,
  web_software_cuts: `Identificação dos próximos cortes`,
  web_software_cuts_description: `Identificação dos próximos cortes`,
  web_software_lots: `Avaliação de lotes para venda`,
  web_software_lots_description: `Avaliação de lotes para venda`,
  web_software_stats: `Estatísticas`,
  web_software_stats_description: `Estatísticas Florestais`,
  web_software_history: `Histórico de dados`,
  web_software_history_description: `Histórico de dados`,
  web_software_gps: `GPS coordenado`,
  web_software_circumference: `Circunferência`,
  web_software_height: `Altura`,
  web_software_conicity: `Cirio`,
  web_software_specie: `Espécies`,
  web_software_diameter: `Diâmetro`,
  web_software_quality: `Qualidade`,
  web_software_volume: `Volume`,
  web_footer_head_office: `Sede`,
  web_footer_belgium: `Bélgica`,
  web_footer_vat: `IVA: 0668.896.459`,
  web_footer_office: `Escritório`,
  web_team: `O Timbtrack`,
  web_team_team: `A equipa`,
  web_team_ca: `O conselho de diretores`,
  web_timbtrack_office: `Escritório Timbtrack`,
  web_timbtrack_office_address: `Rue de Néverlée 1, 1º andar`,
  web_total: `Total`,
  web_vat: `IVA`,
  web_vat_double_check: `Por favor, verifique o seu número de IVA`,
  web_vat_excluded: `IVA excluído`,
  web_wait: `Por favor espere`,
  web_watch_the_movie: `Veja o filme`,
  web_zip: `Código postal`,
  web_technical_information: `Informação técnica`,
  web_inventory: `Inventário`,
  web_reset_password: `Limpar password`,
  web_save: `Guardar`,
  web_terms_and_conditions: `Termos e condições`,
  web_sold_out_product: `Fora de estoque`,
  web_sold_out_product_subtitle: `Entraremos em contato por e-mail quando este item estiver de volta em estoque.`,
  web_forest: `Floresta`,
  web_agricultural: `Agrícola`,
  web_protected_area: `Área protegida`,
  web_secure_payment: `Pagamento seguro`,
  web_delivery_emc: `Entrega dentro de 3 meses`,
  web_promo_code: `Digite seu código promocional (opcional)`,
  web_validate: `Validar`,
  web_jobs: `Jobs`,
  web_full_time: `Período integral`,
  web_rhisnes: `Rhisnes (Namur) - Bélgica`,
  web_job_description: `Descrição do trabalho`,
  web_spontaneous_application: `Aplicação espontânea`,
  web_spontaneous_application_description: `A Timbtrack está crescendo e constantemente buscando talentos prontos para contribuir para o manejo florestal sustentável (e ativos rurais). Não hesite em nos enviar seu currículo.`,
  web_our_events: `Nossos eventos`,
  web_payment_sign_up: `Eu sou novo aqui`,
  web_management_platform: `Assinatura da plataforma de gerenciamento`,
  web_measuring_tools: `Ferramentas de medição`,
  web_validate_plan: `Eu valido o plano`,
  web_plan: `Subscrição`,
  web_new_plan: `Novo plano`,
  web_do_not_change_subscription: `Não quero mudar minha assinatura`,
  web_do_not_change_tools: `Eu não quero ferramentas de medição`,
  web_payment_subscription_platform: `Pagamento da assinatura da plataforma`,
  web_payment_tools: `Pagamento pelos produtos`,
  web_payment_trunk_mail: `NOTA: Se você não recebeu este email, verifique primeiro sua pasta Lixo Eletrônico.`,
  web_payment_credit_card: `Pagamento por cartão de crédito e transferência bancária`,
  web_password_reset_sent: `Um email foi enviado para o seguinte endereço de email`,
  web_below_current_plan: `Você deve entrar em contato com um de nossos colaboradores para reduzir o plano.`,
  web_current_plan: `Plano atual`,
  web_per_month: `Por mês`,
  web_one_year_contract: `Contrato de um ano`,
  web_card_error: `Ocorreu um erro no seu cartão de crédito`,
  web_add_credit_card: `Adicionar cartão de crédito`,
  web_google: `Timbtrack | Ferramentas de Medição e Plataforma de Manejo Florestal`,
  web_google_moto: `Por meio de suas ferramentas de medição de árvores conectadas e sua Plataforma de Gerenciamento Florestal, a Timbtrack desenvolve soluções inovadoras e eficientes para o setor florestal, economizando de 25 a 70% do tempo em dimensionamento, inventário e gestão.`,
  web_uvp_timbtrack: `Ferramentas de medição e plataforma de gerenciamento florestal`,
  web_moto_timbtrack: `Inovação, Simplicidade, Economia de Tempo`,
  web_moto2_timbtrack: `Medir é entender sua floresta`,
  web_moto_description_timbtrack: `O Timbtrack melhora e simplifica o manejo florestal, reduzindo significativamente o tempo e os custos de medição e aprimoramento de sua floresta.`,
  web_uvp_emc: `O medidor de floresta registrada`,
  web_moto_emc: `Perfeitamente adequada para proprietários, gerentes e técnicos de florestas, a EMC fornece uma medição digital de localização geográfica, rápida e precisa de árvores. A ferramenta torna possível acelerar e simplificar os inventários florestais e as operações de dimensionamento. Vinculada à plataforma, a EMC economiza tempo de medição e segurança de dados para decisões sustentáveis de gerenciamento florestal.`,
  web_uvp_tpm: `Tree Parameter Measuring`,
  web_uvp2_tpm: `A ferramenta de medição de árvores mais avançada do mercado`,
  web_moto_tpm: `Desenvolvido para profissionais florestais, o TPM é a ferramenta a medição de árvores mais avançada do mercado até hoje. graça tecnologias de laser, imagem e geolocalização pico, o TPM eleva os parâmetros silviculturais essenciais para manejo florestal preciso e sustentável (altura, circunferência, qualidade, posição GPS etc.). Todos os dados coletados são processados automaticamente pela plataforma de gerenciamento Timbtrack.`,
  web_uvp_platform: `Plataforma`,
  web_uvp2_platform: `Plataforma de manejo florestal e ativos rurais`,
  web_moto_platform: `Estética intuitiva e orientada para a eficiência, a plataforma permite importar seus dados de campo em 3 cliques, analisá-los automaticamente e classificá-los ao longo do tempo. As informações necessárias para o manejo florestal e seus ativos rurais nunca foram tão acessíveis e facilmente comunicadas. Utilizável de forma independente, é também a continuidade natural de nossas ferramentas de medição.`,
  web_gps_emc: `Leitura automática de pontos GPS em cada árvore medida`,
  web_circumference_emc: `Meça a circunferência de suas árvores de maneira rápida e precisa até 6m. Conversível em diâmetro na plataforma`,
  web_height_emc: `Aumente a altura de suas árvores, por padrão em cada lote, ou individualmente`,
  web_conicity_emc: `Aumente a taxa de conicidade padrão (taxa de rolagem / ...) em cada lote ou individualmente`,
  web_volume_emc: `Volume calculado automaticamente na plataforma`,
  web_species_emc: `Registre as espécies, subespécies ou qualidade da árvore padrão em cada lote ou individualmente`,
  web_numerotation_emc: `Numeração automática de cada árvore medida`,
  web_numerotation: `Numeração`,
  web_seconds_measured_tree_emc: `8 segundos por árvore medida`,
  web_time_saving_field_emc: `Economia de tempo em campo: 25 a 50%`,
  web_time_saving_office_emc: `Economia de tempo no escritório: 100%`,
  web_autonomy_emc: `1 dia útil`,
  web_warranty_emc: `2 anos`,
  web_weight_emc: `300g`,
  web_moto2_platform: `CONECTADO À PLATAFORMA TIMBTRACK`,
  web_moto3_platform: `A plataforma Timbtrack permite que você tenha uma visão global de toda a sua floresta. Graças aos diferentes módulos desenvolvidos pela Timbtrack, o gerenciamento de dados florestais é automatizado e instantâneo, economizando um tempo consideráve.`,
  web_gps_tpm: `Leitura automática de pontos GPS em cada árvore medida`,
  web_circumference_tpm: `Meça com rapidez e precisão o diâmetro das árvores, conversíveis em circunferência na plataforma Timbtrack`,
  web_height_tpm: `Meça a altura precisa de suas árvores em um clique`,
  web_conicity_tpm: `Aumente a taxa de conicidade (taxa de rolagem / ....) Em um clique`,
  web_species_tpm: `Aumente a essência da árvore em um clique`,
  web_volume_tpm: `Cálculo automático do volume da árvore, no campo`,
  web_quality_tpm: `Aumente a qualidade da árvore`,
  web_numerotation_tpm: `Numeração automática de cada árvore medida`,
  web_seconds_measured_tree_tpm: `3 segundos por árvore medida`,
  web_time_saving_field_tpm: `Economia de tempo em campo: 60 a 80%`,
  web_time_saving_office_tpm: `Economia de tempo no escritório: 100%`,
  web_autonomy_tpm: `1 dia útil`,
  web_warranty_tpm: `2 ans`,
  web_weight_tpm: `770g`,
  web_safe_about: `Por meio de suas soluções, a Timbtrack está comprometida em fornecer a você um produto SAFE`,
  web_safe_security_about: `Segurança: segurança ideal para coleta e classificação de seus dados`,
  web_safe_security: `Segurança`,
  web_safe_audacity_about: `Uma inovação permanente que dá acesso a tecnologias avançadas`,
  web_safe_audacity: `Audacity`,
  web_safe_faithful_about: `Soluções robustas e de longo prazo`,
  web_safe_faithful: `Confiabilidade`,
  web_safe_evolution_about: `Desenvolvimento contínuo, atendendo às suas necessidades em campo`,
  web_safe_evolution: `Evolução`,
  web_history_timbtrack: `Retornando de uma experiência empreendedora na África, Quentin d'Huart, fundador e acionista da empresa, decidiu abordar o mercado florestal em 2013. Ele está assistindo seu pai agir na floresta e passar um tempo considerável. diante de um computador, ele entendeu que era necessário trazer tecnologias inovadoras nesse setor tradicional. Em consulta com especialistas em silvicultura e partes interessadas na indústria da madeira, ficou claro para ele que um dispositivo sofisticado poderia ajudá-los a se concentrar em sua paixão, a floresta, evitando toda a sobrecarga administrativa que esse trabalho implica. . Ele reuniu uma equipe de engenheiros e designers belgas para iniciar este novo projeto. Logo, os primeiros desenhos se tornaram um verdadeiro protótipo; o TPM nasceu! Para oferecer uma gama completa de produtos, as equipes da Timbtrack continuaram seu desenvolvimento e criaram o EMC, o medidor de registro conectado. Ao mesmo tempo, a plataforma de gerenciamento florestal Timbtrack foi criada para permitir o monitoramento preciso e o gerenciamento sustentável dos ativos rurais. Esse software é a continuação natural de nossas ferramentas. Hoje, a Timbtrack está posicionada como líder belga em ferramentas digitais para o manejo florestal.`,
  web_parcel_platform: `Parcela`,
  web_area_platform: `Cálculo de superfícies (ha)`,
  web_history_platform: `Dados históricos`,
  web_batches_sales_platform: `Avaliação de lotes para venda`,
  web_inventory_cubing_platform: `inventário e escala automatizada`,
  web_stats_platform: `Estatísticas silviculturais e financeiras`,
  web_wood_cut_platform: `Identificação dos próximos cortes`,
  web_bio_dead_trees_platform: `Arvore de interesse biológico e morte`,
  web_comment_platform: `Comentário da Administração`,
  web_modern_interface_platform: `UMA INTERFACE MODERNA`,
  web_modern_interface_moto_platform: `Intuitiva, fluida e profissional, nossa plataforma otimiza o gerenciamento de seus dados florestais. A era da codificação manual acabou!`,
  web_automatic_function_platform: `FUNÇÕES AUTOMÁTICAS`,
  web_automatic_function_moto_platform: `Nossa plataforma gera automaticamente suas estatísticas de inventário. Economizando tempo e visibilidade garantidos na análise de seus dados.`,
  web_simplified_communication_platform: `COMUNICAÇÃO SIMPLIFICADA`,
  web_simplified_communication_moto_platform: `Graças às ferramentas de compartilhamento, nunca foi tão fácil comunicar suas informações aos interlocutores relevantes.`,
  web_digital_safe_platform: `UM SEGURO DIGITAL`,
  web_digital_safe_moto_platform: `Hospedado em nossa nuvem protegida, acesse seus dados de qualquer navegador com segurança.`,
  web_or: `ou`,
  web_account_already_have: `Você já tem uma conta com este endereço`,
  web_loading_information: `Carregando suas informações ...`,
  web_yearly: `Anual`,
  web_monthly: `Por mês`,
  web_save_percentage: `-10%`,
  web_year: `ano`,
  web_per_year: `Por ano`,
  web_immediate_payment: `pagamento imediato`,
  web_access_platform: `Acesso à plataforma`,
  web_your_pack: `Seu pacote`,
  web_info_sent_mail_bank_transfer: `As informações serão enviadas a você por e-mail e também aparecerão na etapa de validação`,
  web_platform_contract: `acesso à plataforma`,
  web_payment_by_month: `Pagamento por mês possível`,
  web_year_2: `ano`,
  web_connect_to_platform: `Conecte-se à plataforma`,
  web_already_own_platform: `Eu já possuo a plataforma`,
  web_single_payment: `Pagamento único`,
  web_for_year: `por 1 ano`,
  web_subscription_platform: `Assinatura da plataforma`,
  web_buy_1_emc_20_ha: `Você deve comprar pelo menos 1 EMC e 20 ha. Se você já possui a plataforma, clique no link "Eu já sou o proprietário da plataforma".`,
  web_confirm_buy_agree_1: `Ao clicar em comprar e validar, você concorda que:`,
  web_confirm_buy_agree_2: `Para garantir um serviço ininterrupto, sua assinatura será liquidada com a renovação total do pagamento automático des`,
  web_confirm_buy_agree_2_tva: `(mais impostos aplicáveis)`,
  web_confirm_buy_agree_3: `com seu próximo pagamento devido em`,
  web_confirm_buy_agree_4_year: `Você nos autoriza a retirar esse valor do seu cartão a cada ano`,
  web_confirm_buy_agree_4_month: `Você nos autoriza a receber pagamentos mensais do seu cartão para atingir o valor total de renovação automática por ano.`,
  web_confirm_buy_agree_4_no_vat_europe: `Você pode cancelar sua assinatura dentro de 15 dias a partir do primeiro pagamento`,
  web_confirm_buy_agree_5: `Você pode desativar a renovação a qualquer momento do seu perfil na plataforma (app.timbtrack.com) ou entrando em contato`,
  web_confirm_buy_agree_6: `Você pode cancelá-lo da sua conta sem entrar em contato conosco dentro de 14 dias antes do término do seu contrato. Você também concorda com a nossa`,
  web_confirm_buy_agree_7: `e confirme que você leu e entendeu nossa`,
  web_confirm_buy_title: `Verificação e validação do seu voto`,
  web_transfer_information: `Abaixo, você encontrará as informações necessárias para o pagamento da sua transferência. O processo de entrega começará assim que confirmarmos o recebimento do pagamento.`,
  web_app_baseline: `Manejo Florestal e Ativos Rurais`,
  web_visors_caregivers: `Viseira completa anti-salpicos`,
  web_visors_paragraph_1: `A Timbtrack desenvolveu um visor projetado para cumprir os procedimentos de higiene implementados no contexto da crise do Covid 19. Esta máscara de proteção facial foi projetada para proteger as pessoas ativas durante a crise do Covid 19.`,
  web_visors_paragraph_2: `Apoie o projeto, suas doações têm um impacto!`,
  web_visors_visit_site: `Visite o site`,
  web_visors_make_donation: `Faça uma doação`,
  rules_first_name_required: `O primeiro nome é necessário`,
  rules_last_name_required: `O sobrenome é obrigatório`,
  rules_phone_number_required: `O número de telefone não está no formato internacional válido`,
  rules_email_required: `Endereço de email inválido`,
  rules_email_required_confirm: `Confirme se o endereço de e-mail é obrigatório`,
  rules_password_dont_match: `As senhas não coincidem`,
  rules_password_required: `Nova senha é necessária`,
  rules_password_not_secure: `A senha não é segura o suficiente (insira 8 caracteres com números e letras maiúsculas)`,
  rules_confirm_new_password: `Confirme se a nova senha é necessária`,
  web_mail_request: `Entraremos em contato por e-mail com sua solicitação.`,
  web_sawmill: `Serraria`,
  web_testing: `Em teste ...`,
  web_app_soon:`Medir suas árvores com seu telefone logo será possível!`,
  web_app_soon_store_play:`Em breve na App Store e no Google Play!`,
};
