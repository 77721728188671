import axios from 'axios';

const http = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }
    config.headers['language'] = localStorage.getItem('language') || 'en';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export {
  http,
};
