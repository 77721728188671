<template>
    <v-app class="timbtrack-container">
        <v-content>
            <router-view></router-view>
        </v-content>
    </v-app>
</template>

<script>
    import jwt from 'jsonwebtoken';
    import VueRouter from 'vue-router';
    import store from './stores';
    import { languages } from './stores/constants';

    const adminGuard = (to, from, next) => {
      const token = jwt.decode(localStorage.getItem('token'));
      if (token && token.role === 'admin') {
        next();
      } else {
        next(from);
      }
    };

    const userGuard = (to, from, next) => {
      if (jwt.decode(localStorage.getItem('token'))) {
        next();
      } else {
        next(from);
      }
    };

    const router = new VueRouter({
      mode: 'history',
      routes: [
       // {path: '/order', component: () => import('./vitrine/PageOrderProduct.vue')},
        {path: '/', component: () => import('./vitrine/Vitrine.vue'), children: [
         /*    {path: '', component: () => import('./vitrine/PageHome.vue')},
           {path: 'about', component: () => import('./vitrine/PageAbout.vue')},
            {path: 'contact', component: () => import('./vitrine/PageContact.vue')},
            {path: 'products', component: () => import('./vitrine/PageProducts.vue'), children: [
                {path: 'tpm', component: () => import('./vitrine/products/Tpm.vue')},
                {path: 'emc', component: () => import('./vitrine/products/Emc.vue')},
              ]},
          /*  {path: '/software', component: () => import('./vitrine/PageSoftware.vue')},
            {path: '/jobs', component: () => import('./vitrine/Jobs.vue')},
            {path: 'help', component: () => import('./sidepages/QuickStarts.vue')},
            {path: 'press', component: () => import('./vitrine/PagePress.vue')},
            {path: 'legagls', component: () => import('./vitrine/PageLegals.vue'), children: [
                {path: 'terms-and-conditions', alias: '', component: () => import('./vitrine/legals/TermsAndConditions.vue')},
                {path: 'licence-saas', component: () => import('./vitrine/legals/LicenceSaas')},
              ]},*/
            {path: '*', component: () => import('./vitrine/PageHome.vue')},
          ]},
      ],
    });

    router.afterEach(() => {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 200);
    });

    export default {
      name: 'Timbtrack',
      store,
      router,
      data: () => ({
        timerLogout: undefined,
      }),
      created() {
        const savedLanguage = localStorage.getItem('language');
        const navigatorLanguage = navigator.language.split('-')[0];
        const foundLanguage = languages.find(l => l.value === (savedLanguage || navigatorLanguage));
        const defaultLanguage = languages.find(l => l.value === 'en');
        const language = foundLanguage || defaultLanguage;
        this.$store.dispatch('switchLanguage', language);
        this.$i18n.locale = language.value;
        // if(!jwt.decode(localStorage.getItem('token'))) {
        //   localStorage.removeItem('token');
        //   return;
        // }
        // this.$store.dispatch('fetchProfile')
        //   .then(() => {
        //     // this.startTimer();
        //   })
        //   .catch(() => {
        //     localStorage.removeItem('token');
        //   });
      },
    }
</script>

<style lang="scss" scoped>
    .timbtrack-container {
        height: 100%;
        background-color: white;
    }
</style>
