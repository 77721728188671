export default () => {
  const head = document.getElementsByTagName('head')[0];

  const insertBefore = head.insertBefore;

  head.insertBefore = (newElement, referenceElement) => {
    if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) return;
    insertBefore.call(head, newElement, referenceElement);
  };
};
