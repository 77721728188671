export default {
  AT: 'Áustria',
  BE: 'Bélgica',
  BG: 'Bulgária',
  HR: 'Croácia',
  CY: 'Chipre',
  CZ: 'República Checa',
  DK: 'Dinamarca',
  FI: 'Finlândia',
  FR: 'França',
  DE: 'Alemanha',
  EL: 'Grécia',
  HU: 'Hungria',
  IE: 'Irlanda',
  IT: 'Itália',
  LV: 'Letónia',
  LT: 'Lituânia',
  LU: 'Luxemburgo',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Polónia',
  PT: 'Portugal',
  RO: 'Roménia',
  SG: 'Cingapura',
  SK: 'Eslováquia',
  SI: 'Eslovénia',
  ES: 'Espanha',
  SE: 'Suécia',
  UK: 'United Kingdom',
  IS: 'Islândia',
  LI: 'Liechtenstein',
  NO: 'Noruega',
  CH: 'Suíça',
};
