export default {
  AT: 'Autriche',
  BE: 'Belgique',
  BG: 'Bulgarie',
  HR: 'Croatie',
  CY: 'Chypre',
  CZ: 'République tchèque',
  DK: 'Danemark',
  FI: 'Finlande',
  FR: 'France',
  DE: 'Allemagne',
  EL: 'Grèce',
  HU: 'Hongrie',
  IE: 'Irelande',
  IT: 'Italie',
  LV: 'Lettonie',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MT: 'Malte',
  NL: 'Pays-Bas',
  PL: 'Pologne',
  PT: 'Portugal',
  RO: 'Roumanie',
  SG: 'Singapour',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  ES: 'Espagne',
  SE: 'Suède',
  UK: 'Royaume-Uni',
  IS: 'Islande',
  LI: 'Liechtenstein',
  NO: 'Norvège',
  CH: 'Suisse',
};
