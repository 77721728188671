/* eslint quotes: [`error`, `backtick`] */

export default {
  web_available: `Beschikbaar`,
  web_exhausted: `Uitverkocht`,
  web_accept_terms_and_privacy_1: `Ik aanvaard`,
  web_accept_terms_and_privacy_2: `gebruiksvoorwaarden`,
  web_accept_terms_and_privacy_3: `en de`,
  web_accept_terms_and_privacy_4: `Privacy beleid`,
  web_about: `Wie zijn wij`,
  web_already_have_account: `Heeft u al een account?`,
  web_cancel: `Annuleren`,
  web_circumference: `Omtrek`,
  web_city: `Stad`,
  web_comment: `Reactie`,
  web_company_name: `Bedrijfsnaam`,
  web_optional: `(optioneel)`,
  web_confirm: `Bevestigen`,
  web_contact: `Contact`,
  web_contact_success: `Uw aanvraag werd goed ontvangen, wij komen zo snel mogelijk naar u terug. Alvast hartelijk dank voor uw interesse in onze producten!`,
  web_country: `Land`,
  web_delete: `Verwijderen`,
  web_email: `E-mail`,
  web_emc_in_practice: `In praktijk`,
  web_first_name: `Voornaam`,
  web_forgot_password: `Wachtwoord vergeten?`,
  web_form_required_email_confirm: `Bevestig e-mail`,
  web_form_required_field: `Vereist veld`,
  web_gdpr_signup_information: `Bij het aanmaken van een account, erkent u dat Timbtrack u toegang verleent tot een versie van de digitale diensten voor beheer en classificatie van bosbeheergegevens.<br/><br/>Deze services zijn aangeboden door I.B.R. S.A, eigenaar van het merk Timbtrack.<br/><br/>,:m deze services correct te laten functioneren, u beveiligd toegang tot het platform te verlenen en banden te kunnen leggen tussen de gebruikers van de Timbtrack gemeenschap, heeft Timbtrack uw persoonlijke gegevens nodig.<br/><br/>Uw gegevens worden nooit zonder uw uitdrukkelijke toestemming aan derden verkocht. Wij garanderen dat deze gevoelige informatie, zonder uw toestemming, nooit zal worden gebruikt om andere doeleinden dan die van het runnen van de digitale diensten van Timbtrack.<br/><br/>Persoonlijke gegevens die wij verzamelen zijn de volgende: <b>Uw Naam</b>, <b>Uw Voornaam</b>, <b>Uw e-mail adres</b>, <b>Uw telefoonnummer</b>, <b>Eigendomsadres(sen)</b><br/><br/>Indien u uw akkoord geeft, ontvangt u een persoonlijke en beveiligde toegang. Deze is toegankelijk via “Login” op de website www.timbtrack.com met uw gebruikersnaam en wachtwoord, enkel door u gekend. U kunt op elk moment uw persoonlijke gegevens raadplegen, bewerken of laten verwijderen.<br/><br/>Let op : wij raden u aan om deze toegang vertrouwelijk te houden.<br/><br/>Wij staan tot uw beschikking voor alle vragen die u zou hebben. Stuur ons een mail naar info@timbtrack.com.<br/><br/>Het Timbtrack team`,
  web_gdpr_title: `In verband met uw persoonlijke gegevens`,
  web_get_in_touch: `Contacteer ons`,
  web_height: `Hoogte`,
  web_history: `Het begin…`,
  web_home: `Home`,
  web_i_agree: `Ik aanvaard`,
  web_included: `Inbegrepen`,
  web_information: `Informatie`,
  web_last_name: `Naam`,
  web_learn_more: `MEER WETEN`,
  web_learn_more_lower: `Meer weten`,
  web_login: `Login`,
  web_message: `Bericht`,
  web_mission: `Onze missie, het digitaal bos.`,
  web_next: `Volgende`,
  web_no: `Neen`,
  web_password: `Paswoord`,
  web_payment: `Betaling`,
  web_phone: `Telefoon`,
  web_phone_number: `Telefoonnummer`,
  web_platform: `Platform`,
  web_platform_sold_out: `Uitverkocht`,
  web_press: `Pers`,
  web_press_releases: `Persoverzicht`,
  web_product: `Product`,
  web_product_preorder: `Voorbestelling`,
  web_product_preordering: `Voorbestellen`,
  web_products: `Producten`,
  web_send: `Verzenden`,
  web_sign_up: `INSCHRIJVEN`,
  web_software_month: `maand`,
  web_step: `stap`,
  web_step_1: `Aanmelden`,
  web_step_2: `Producten`,
  web_step_3: `Adressen`,
  web_step_4: `Betaling`,
  web_step_5: `Bevestiging`,
  web_step_1_billing: `Stap 1: Facturatieadres`,
  web_step_1_shipping_address: `Leveringsadres`,
  web_step_1_billing_address: `Facturatieadres`,
  web_step_1_same_to_billing_address: `Idem aan facturatieadres`,
  web_step_1_email_info: `Verzend factuur van uw bestelling`,
  web_step_1_save_address: `Adres registreren`,
  web_step_2_payment: `Stap 2: Betaling`,
  web_step_2_payment_method: `Betalingswijze`,
  web_step_2_payment_method_credit_card: `Kredietkaart`,
  web_step_2_payment_method_immediate_bank: `Onmiddelijke overschrijving`,
  web_step_2_payment_method_paypal: `Paypal`,
  web_step_2_payment_method_maestro: `Maestro`,
  web_step_2_payment_add_card: `Een nieuwe kaart toevoegen`,
  web_step_2_payment_validity: `geldig tot`,
  web_step_2_delivery: `Levering`,
  web_step_2_vat: `BTW`,
  web_step_2_order: `Bestelling`,
  web_software: `Platform`,
  web_step_3_validation_pay_and_validate: `Kopen en valideren`,
  web_step_4_access_plateforme: `Naar het Timbtrack platform`,
  web_step_4_done_thank_you: `Hartelijk dank voor uw bestelling!`,
  web_step_4_done_email: `Uw krijgt een bevestiging op volgend e-mail adres:`,
  web_street: `Straat en nummer`,
  web_software_order: `Bestellen`,
  web_software_area: `Berekening van de oppervlakte (ha)`,
  web_software_area_description: `Berekening van de oppervlakte`,
  web_software_cuts: `Identificatie van de toekomstige boskappen`,
  web_software_cuts_description: `Identificatie van de toekomstige boskappen`,
  web_software_lots: `Waardering van de kavels`,
  web_software_lots_description: `Waardering van de kavels`,
  web_software_stats: `Statistiek`,
  web_software_stats_description: `Bosbouwstatistiek`,
  web_software_history: `Historische gegevens`,
  web_software_history_description: `Historische gegevens`,
  web_software_gps: `GPS-coördinaten`,
  web_software_circumference: `Omtrek`,
  web_software_height: `Hoogte`,
  web_software_conicity: `Coniciteit`,
  web_software_specie: `Soorten`,
  web_software_diameter: `Diameter`,
  web_software_quality: `Kwaliteit`,
  web_software_volume: `Volume`,
  web_footer_head_office: `Hoofdkantoor`,
  web_footer_belgium: `België`,
  web_footer_vat: `BTW: BE 0668.896.459`,
  web_footer_office: `Kantoor`,
  web_team: `Het Trackteam`,
  web_team_team: `Het team`,
  web_team_ca: `Het raad van bestuur`,
  web_timbtrack_office: `Kantoor Timbtrack`,
  web_timbtrack_office_address: `Rue de Néverlée 1, 1ste verdieping`,
  web_total: `Totaal`,
  web_vat: `BTW`,
  web_vat_double_check: `Controleer uw BTW-nummer opnieuw aub`,
  web_vat_excluded: `exclusief BTW`,
  web_wait: `Even geduld aub`,
  web_watch_the_movie: `Bekijk de video`,
  web_zip: `Postcode`,
  web_technical_information: `Technische informatie`,
  web_inventory: `Inventaris`,
  web_reset_password: `Wachtwoord opnieuw instellen`,
  web_save: `Opslaan`,
  web_terms_and_conditions: `Algemene voorwaarden`,
  web_sold_out_product: `Product uitverkocht`,
  web_sold_out_product_subtitle: `Wij houden u via e-mail op de hoogte wanneer dit artikel opnieuw beschikbaar is.`,
  web_forest: `Bos`,
  web_agricultural: `Landbouw`,
  web_protected_area: `Beschermd gebied`,
  web_secure_payment: `Beveiligde betaling`,
  web_delivery_emc: `Levering binnen de 3 maanden`,
  web_promo_code: `Geef uw promo-code in (optioneel)`,
  web_validate: `Bevestigen`,
  web_jobs: `Jobs`,
  web_full_time: `Voltijds`,
  web_rhisnes: `Rhisnes (Namen) - België`,
  web_job_description: `Beschrijving van de werkzaamheden`,
  web_spontaneous_application: `Open sollicitatie`,
  web_spontaneous_application_description: `Timbtrack groeit en is constant op zoek naar talenten die willen bijdragen aan duurzaam bosbeheer (en bevordering van plattelandscapaciteiten). Aarzal niet ons uw CV door te sturen.`,
  web_our_events: `Onze evenementen`,
  web_payment_sign_up: `Ik ben hier nieuw`,
  web_management_platform: `Beheerplatform`,
  web_measuring_tools: `Meetapparaten`,
  web_validate_plan: `Ik bevestig het plan`,
  web_plan: `Abonnement`,
  web_new_plan: `Nieuw plan`,
  web_do_not_change_subscription: `Ik wil mijn abonnement niet wijzigen`,
  web_do_not_change_tools: `Ik wil geen meetapparaten`,
  web_payment_subscription_platform: `Betaling abonnement voor het platform`,
  web_payment_tools: `Betaling voor de producten`,
  web_payment_trunk_mail: `OPMERKING: Check eerst uw spamfolder indien u deze e-mail niet heeft gekregen.`,
  web_payment_credit_card: `Betaling per creditcard en bankoverschrijving`,
  web_password_reset_sent: `Een e-mail werd net verzonden naar volgend e-mail adres`,
  web_below_current_plan: `U kunt niet onder uw actueel plan gaan`,
  web_current_plan: `Actueel plan`,
  web_per_month: `Per maand`,
  web_one_year_contract: `Eenjarig contract`,
  web_card_error: `Er is een fout opgetreden met de kaart die u hebt verstrekt voor de betaling`,
  web_add_credit_card: `Kredietkaart toevoegen`,
  web_google: `Timbtrack | Meetapparaten en platform voor Bosbeheer`,
  web_google_moto: `Via zijn gekoppelde boommeetapparaten et zijn Platform voor Bosbeheer ontwikkelt Timbtrack innovatieve en efficiënte oplossingen voor de bosbouwsector, waardoor een tijdwinst van 25% tot 70% op volumebepaling, inventarisatie en beheer mogelijk is.`,
  web_uvp_timbtrack: `Meetapparaten en platform voor bosbeheer`,
  web_moto_timbtrack: `Innovatie, Eenvoud, Tijdwinst`,
  web_moto2_timbtrack: `Meten is uw bos begrijpen`,
  web_moto_description_timbtrack: `Timbtrack verbetert en vereenvoudigt bosbeheer terwijl het aanzienlijk tijd en kosten, besteed aan het meten en benutten van uw bos, vermindert.`,
  web_uvp_emc: `De gekoppelde boommeter`,
  web_moto_emc: `Uitermate geschikt voor boseigenaren, managers en technici in bosbeheer, de EMC biedt een snelle,  nauwkeurige en geogelokaliseerde digitale meting van bomen. Het apparaat versnelt en vereenvoudigt de inventarisering van bossen en de volumebepaling van bomen. De EMC, gekoppeld aan het platform, biedt meettijdwinst en verhoogde gegevensbeveiliging, waardoor beslissingen over duurzaam bosbeheer eenvoudiger worden.`,
  web_uvp_tpm: `Tree Parameter Measuring`,
  web_uvp2_tpm: `Het meest geavanceerde boommeetapparaat op de markt`,
  web_moto_tpm: `De TPM, ontwikkeld voor professionele bosbouwers, is tot nu toe de meest geavanceerde tool voor het meten van bomen op de markt. Dankzij ultramoderne laser-, beeld- en geolocalisatietechnieken identificeert de TPM de essentiële paramaters voor een nauwkeurig en duurzaam bosbeheer (hoogte, omtrek, kwaliteit, GPS-positie, enz.). Alle verzamelde gegevens worden automatisch verwerkt door het Timbtrack beheerplatform.`,
  web_uvp_platform: `Platform`,
  web_uvp2_platform: `Platform voor bosbouwbeheer en beheer van landelijke activa`,
  web_moto_platform: `Intuïtief, estetisch en doelgericht, het platform stelt u in staat om uw gegevens in 3 stappen te importeren, deze automatisch te analyseren en chronologisch te rangschikken. De informatie die nodig is voor het beheer van uw bossen en landelijke activa is nog nooit toegankelijker et gemakkelijk te door te geven geweest. Het platform is online gekoppeld aan onze meetapparaten, maar kan ook onafhankelijk daarvan gebruikt worden.`,
  web_gps_emc: `Automatische opgave van GPS-punt voor elke gemeten boom`,
  web_circumference_emc: `Meet snel en nauwkeurig de omtrek van uw bomen tot 6m. Omrekenbaar naar diameter op het platform`,
  web_height_emc: `Bepaal de hoogte van uw bomen, standaard voor elk lot, of individueel`,
  web_conicity_emc: `Bepaal de coniciteit, standaard voor elk lot, of individueel`,
  web_volume_emc: `Volume automatisch berekend op het platform`,
  web_species_emc: `Bepaal de soort, ondersoort of kwaliteit van de boom, standaard voor elk lot, of individueel`,
  web_numerotation_emc: `Automatische nummering van elke gemeten boom`,
  web_numerotation: `Nummering`,
  web_seconds_measured_tree_emc: `8 seconden per gemeten boom`,
  web_time_saving_field_emc: `Tijdwinst op terrein: 25 tot 50%`,
  web_time_saving_office_emc: `Tijdwinst op kantoor: 100%`,
  web_autonomy_emc: `1 werkdag`,
  web_warranty_emc: `2 jaar`,
  web_weight_emc: `300g`,
  web_moto2_platform: `GEKOPPELD AAN HET TIMBTRACK PLATFORM`,
  web_moto3_platform: `Het Timbtrack platform biedt een globaal overzicht van heel uw bos. Dankzij de verschillende door Timbtrack ontwikkelde modules, wordt het beheer van bosbouwgegevens automatisch en direct uitgevoerd, waardoor u aanzienlijk veel tijd bespaart.`,
  web_gps_tpm: `Automatische opgave van GPS-punt voor elke gemeten boom`,
  web_circumference_tpm: `Meet snel en nauwkeurig de diameter van bomen, omrekenbaar naar omtrek op het platform`,
  web_height_tpm: `Meet de hoogte van uw bomen in één klik`,
  web_conicity_tpm: `Bepaal de coniciteit in één klik`,
  web_species_tpm: `Bepaal de soort van de boom in één klik`,
  web_volume_tpm: `Automatische berekening van het volume van de boom op het terrein`,
  web_quality_tpm: `Bepaal de kwaliteit van de boom`,
  web_numerotation_tpm: `Automatische nummering van elke gemeten boom`,
  web_seconds_measured_tree_tpm: `3 seconden per gemeten boom`,
  web_time_saving_field_tpm: `Tijdwinst op terrein: 60 tot 80%`,
  web_time_saving_office_tpm: `Tijdwinst op kantoor: 100%`,
  web_autonomy_tpm: `1 werkdag`,
  web_warranty_tpm: `2 jaar`,
  web_weight_tpm: `770g`,
  web_safe_about: `Via zijn oplossingen, zet Timbtrack zich in om u een VEILIG product te leveren`,
  web_safe_security_about: `Optimale beveiliging bij inzameling en classificatie van uw gegevens`,
  web_safe_security: `Beveiliging`,
  web_safe_audacity_about: `Toegang tot spitstechnologie door continue innovatie`,
  web_safe_audacity: `Lef`,
  web_safe_faithful_about: `Robuuste oplossingen met focus op lange termijn gebruik`,
  web_safe_faithful: `Betrouwbaarheid`,
  web_safe_evolution_about: `Continue ontwikkeling, die voldoet aan uw behoeften op het terrein`,
  web_safe_evolution: `Evolutie`,
  web_history_timbtrack: `Quentin d’Huart, oprichter en aandeelhouder van het bedrijf, besloot in 2013 de bosbouwsector te benaderen na zijn ondernemingservaring in Afrika. Door zijn vader bomen te zien meten en erna aanzienlijk veel tijd achter zijn computer door te brengen, begreep hij dat deze traditionele sector nood had aan innovatieve technologieën. Door overleg met bosbouwdeskundigen en spelers in de houtindustrie werd het hem duidelijk dat een geavanceerd apparaat hen zou kunnen helpen zich te concentreren op hun passie, het bos, door de administratieve last dat dit werk met zich meebrengt te vermijden. Hij riep een team van Belgische ingenieurs en designers samen om dit project in gang te zetten. Binnen de kortste keren werden de eerste schetsen een echt prototype; de TPM werd geboren! Om hun assortiment te vervolledigen, heeft het Timbtrack-team de EMC (Electronic Meter Connect) ontwikkeld. Tegelijkertijd werd het platform voor bosbeheer Timbtrack ontwikkeld, waardoor een  nauwkeurige opvolging en duurzaam beheer van landelijke activa mogelijk is. Deze software is online gekoppeld aan de boommeetapparaten. Vandaag is Timbtrack leider op de Belgische markt op het gebied van digitale bosbeheertools.`,
  web_parcel_platform: `Verkaveld`,
  web_area_platform: `Berekening van het areaal (ha)`,
  web_history_platform: `Geschiedenis van de gegevens`,
  web_batches_sales_platform: `Evaluatie van de kavels`,
  web_inventory_cubing_platform: `Geautomatiseerde inventaris en volumebepaling`,
  web_stats_platform: `Financiële en bosbouwstatistieken`,
  web_wood_cut_platform: `Indentificatie van de toekomstige kappen`,
  web_bio_dead_trees_platform: `Boom van biologisch belang en dood`,
  web_comment_platform: `Opmerking voor het beheer`,
  web_modern_interface_platform: `EEN MODERNE INTERFACE`,
  web_modern_interface_moto_platform: `Intuïtief, naadloos en professioneel. Ons platform optimaliseert het beheer van uw bosgegevens. Het tijdperk van manuele codering is voorbij!`,
  web_automatic_function_platform: `AUTOMATISCHE FUNCTIES`,
  web_automatic_function_moto_platform: `Ons platform genereert automatisch uw inventarisstatistieken. Winst in tijd en inzicht gegarandeerd voor de analyse van uw gegevens.`,
  web_simplified_communication_platform: `EEN VEREENVOUDIGDE COMMUNICATIE`,
  web_simplified_communication_moto_platform: `Via de deelfuncties, was het nog nooit zo simpel uw gegevens aan de betrokken persoon door te sturen.`,
  web_digital_safe_platform: `EEN DIGITALE KLUIS`,
  web_digital_safe_moto_platform: `Krijg in alle rust toegang tot uw gegevens vanuit elke browser via onze beveiligde cloud.`,
  web_or: `of`,
  web_account_already_have: `U heeft al een account met dit adres`,
  web_loading_information: `Uw gegevens laden ...`,
  web_yearly: `Jaarlijks`,
  web_monthly: `Maandelijks`,
  web_save_percentage: `-10%`,
  web_year: `jaar`,
  web_per_year: `Per jaar`,
  web_immediate_payment: `Onmiddellijke betaling`,
  web_access_platform: `Toegang tot het platform`,
  web_your_pack: `Je roedel`,
  web_info_sent_mail_bank_transfer: `De informatie wordt per e-mail naar u verzonden en verschijnt ook in de validatiestap`,
  web_platform_contract: `toegang tot het platform`,
  web_payment_by_month: `Betaling per maand mogelijk`,
  web_year_2: `jaar`,
  web_already_own_platform: `Ik bezit het platform al`,
  web_single_payment: `Enkele betaling`,
  web_for_year: `voor 1 jaar`,
  web_subscription_platform: `Abonnement op het platform`,
  web_buy_1_emc_20_ha: `U moet minimaal 1 EMC en 20 ha kopen. Als u het platform al hebt, klik dan op de link "Ik bezit het platform al".`,
  web_confirm_buy_agree_1: `Door op kopen en valideren te klikken, gaat u ermee akkoord dat:`,
  web_confirm_buy_agree_2: `Om een ononderbroken service te garanderen, wordt uw abonnement ingesteld op continue automatische verlengingsbetalingen van`,
  web_confirm_buy_agree_2_tva: `(plus toepasselijke belastingen)`,
  web_confirm_buy_agree_3: `met uw volgende betaling op`,
  web_confirm_buy_agree_4_year: `U machtigt ons om dit bedrag elk jaar van uw kaart te nemen`,
  web_confirm_buy_agree_4_month: `U machtigt ons om maandelijkse betalingen van uw kaart te nemen om het totale bedrag van de automatische verlenging per jaar te bereiken.`,
  web_confirm_buy_agree_4_no_vat_europe: `U kunt uw abonnement binnen 15 dagen na de eerste betaling annuleren.`,
  web_confirm_buy_agree_5: `Je kunt de verlenging op elk moment deactiveren via je profiel op het platform (app.timbtrack.com) of door contact op te nemen met`,
  web_confirm_buy_agree_6: `U kunt het annuleren vanuit uw account zonder binnen 14 dagen voor het einde van uw contract contact met ons op te nemen. U gaat ook akkoord met onze`,
  web_confirm_buy_agree_7: `en bevestig dat u onze hebt gelezen en begrepen`,
  web_confirm_buy_title: `Verificatie en validatie van uw bestelling`,
  web_transfer_information: `Hieronder vindt u de informatie die nodig is voor de betaling van uw overschrijving. Het leveringsproces begint zodra we de ontvangst van de betaling hebben bevestigd.`,
  web_visors_caregivers: `Anti-spat vol vizier`,
  web_visors_paragraph_1: `Timbtrack heeft een vizier ontwikkeld dat is ontworpen om te voldoen aan de hygiëneprocedures die zijn geïmplementeerd in de context van de Covid 19.-crisis Dit volgelaatsmasker is ontworpen om mensen te beschermen die actief zijn tijdens de Covid 19-crisis.`,
  web_visors_paragraph_2: `Steun het project, uw donaties hebben impact!`,
  web_visors_visit_site: `Bezoek de site`,
  web_visors_make_donation: `Doe een gift`,
  rules_first_name_required: `Voornaam is verplicht`,
  rules_last_name_required: `Achternaam is verplicht`,
  rules_phone_number_required: `Telefoonnummer heeft geen geldig internationaal formaat`,
  rules_email_required: `E-mailadres is ongeldig`,
  rules_email_required_confirm: `Bevestig e-mailadres is verplicht`,
  rules_password_dont_match: `Wachtwoorden komen niet overeen`,
  rules_password_required: `Nieuw wachtwoord is vereist`,
  rules_password_not_secure: `Wachtwoord is niet veilig genoeg (vul 8 tekens in met cijfers en hoofdletters)`,
  rules_confirm_new_password: `Bevestig dat nieuw wachtwoord vereist is`,
  web_mail_request: `We nemen per e-mail contact met u op met uw verzoek.`,
  web_sawmill: `Zagerij`,
  web_testing: `In test...`,
  web_app_soon:`Uw bomen opmeten met uw telefoon is binnenkort mogelijk!`,
  web_app_soon_store_play:`Binnenkort in de App Store en Google Play!`,
};
