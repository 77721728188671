import { http, SUBSCRIPTION } from '../../services';

const SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING';

const state = {
  subscriptionLoading: false,
};

const actions = {
  sendSubscription: async ({ commit }, subscription) => {
    commit(SUBSCRIPTION_LOADING, true);
    try {
      await http.post(SUBSCRIPTION.POST, subscription);
    } catch (error) {
      throw error;
    } finally {
      commit(SUBSCRIPTION_LOADING, false);
    }
  },
};

const mutations = {
  [SUBSCRIPTION_LOADING](state, loading) {
    state.subscriptionLoading = loading;
  },
};

export default {
  state,
  actions,
  mutations,
};
