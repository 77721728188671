import { http, VAT_CHECKER } from '../../services';

const VAT_CHECKER_LOADING = 'VAT_CHECKER_LOADING';

const initialState = () => ({
  vatCheckerLoading: false,
});

const state = {
  ...initialState(),
  initialState,
};

const actions = {
  checkVATNumber: async ({ commit }, params) => {
    commit(VAT_CHECKER_LOADING, true);
    try {
      const { data: { valid } } = await http.get(VAT_CHECKER.GET, { params });
      return valid;
    } catch (e) {
      throw e;
    } finally {
      commit(VAT_CHECKER_LOADING, false);
    }
  },
};

const mutations = {
  [VAT_CHECKER_LOADING](state, loading) {
    state.vatCheckerLoading = loading;
  },
};

export default {
  state,
  actions,
  mutations,
};
