import { http, PROFILE } from '../../services';

const PROFILE_USER_UPDATE = 'PROFILE_USER_UPDATE';
const PROFILE_USER_LOADING = 'PROFILE_USER_LOADING';

const initialState = () => ({
  user: undefined,
  userLoading: false,
});

const state = {
  ...initialState(),
  initialState,
};

const actions = {
  fetchProfile: async ({ commit }) => {
    commit(PROFILE_USER_LOADING, true);
    try {
      const { data: { user } } = await http.get(PROFILE.GET);
      // Success fetching profile: profile can be stored
      commit(PROFILE_USER_UPDATE, user);
    } catch ({ response: { data: { error } } }) {
      // Failed fetching profile: resetting profile in the store
      commit(PROFILE_USER_UPDATE, undefined);
      throw error;
    } finally {
      commit(PROFILE_USER_LOADING, false);
    }
  },
  removeUser: ({ commit }) => {
    commit(PROFILE_USER_UPDATE, undefined);
  },
};

const getters = {
  isLogged: state => state.user,
  isAdmin: state => {
    if (!state.user) {
      return false;
    }
    return state.user.master === 1;
  },
  package: state => {
    if (!state.user) {
      return undefined;
    }
    switch (state.user.package) {
      case 1: return 'FREE';
      case 2: return 'FREE';
    }
    const features = {
      manual: false,
    }
  },
};

const mutations = {
  [PROFILE_USER_UPDATE](state, user) {
    state.user = user;
  },
  [PROFILE_USER_LOADING](state, loading) {
    state.userLoading = loading;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
}
