/* eslint quotes: ["error", "backtick"] */

export default {
  web_available: `Disponible`,
  web_exhausted: `Épuisé`,
  web_accept_terms_and_privacy_1: `J'accepte les`,
  web_accept_terms_and_privacy_2: `Conditions d'utilisation`,
  web_accept_terms_and_privacy_3: `et la`,
  web_accept_terms_and_privacy_4: `Politique de confidentialité`,
  web_about: `Qui sommes-nous`,
  web_already_have_account: `Avez-vous déjà un compte ?`,
  web_cancel: `Annuler`,
  web_circumference: `Circonférence`,
  web_city: `Ville`,
  web_comment: `Commentaire`,
  web_company_name: `Nom de la société`,
  web_optional: ` (facultatif)`,
  web_confirm: `Confirmer`,
  web_contact: `Contact`,
  web_contact_success: `Nous avons bien reçu votre demande de contact et nous reviendrons vers vous dans les plus brefs délais. Merci pour l'intérêt que vous portez à nos produits !`,
  web_country: `Pays`,
  web_delete: `Supprimer`,
  web_email: `E-mail`,
  web_emc_in_practice: `En pratique`,
  web_first_name: `Prénom`,
  web_forgot_password: `Mot de passe oublié ?`,
  web_form_required_email_confirm: `Confirmez l'adresse mail`,
  web_form_required_field: `Ce champs est requis`,
  web_gdpr_signup_information: `En créant un compte, vous reconnaissez que Timbtrack vous donne accès à une version de ses services digitaux dans la gestion et la classification des données forestières.<br/></br>Ces services sont proposés par I.B.R. S.A. qui détient la marque “Timbtrack”.<br/></br>Afin de faire fonctionner proprement ces services, Timbtrack a besoin d’utiliser vos données personnelles pour créer votre accès, rendre celui-ci sécurisé et créer des liens entre les utilisateurs de la communauté Timbtrack.<br/></br>Vos données ne seront jamais vendues à des tiers sans votre accord explicite. Nous garantissons que, sans votre permission, ces informations sensibles ne vont jamais être utilisées pour d’autres raisons que celles de faire tourner les services digitaux de Timbtrack.<br/></br>Les informations personnelles que nous collectons sont les suivantes: <b>nom</b>, <b>prénom</b>, <b>adresse e-mail</b>, <b>numéro de téléphone</b> et vos <b>adresse(s) de propriétés</b><br/></br>Lorsque vous donnez votre accord, vous recevez un accès personnel et protégé. Celui-ci est accessible via le “Login” sur la page web www.timbtrack.com avec votre identifiant et mot de passe, connus de vous uniquement. Vous pouvez à tout moment consulter, éditer et supprimer vos données personnelles dans le cadre de votre contrat qui est régi par des obligations. Ces données sont détenues et utilisées par IBR S.A.<br/></br>Attention: nous vous recommandons de bien garder cet accès confidentiel.<br/></br>Nous sommes à votre disposition pour toute question que vous auriez à l’adresse e-mail info@timbtrack.com.<br/></br>L'équipe Timbtrack<br/>`,
  web_gdpr_title: `À propos de vos données personnelles`,
  web_get_in_touch: `Contactez-nous`,
  web_height: `Hauteur`,
  web_history: `Le commencement...`,
  web_home: `Home`,
  web_i_agree: `J'accepte`,
  web_included: `Inclus`,
  web_information: `Informations`,
  web_last_name: `Nom`,
  web_learn_more: `EN SAVOIR PLUS`,
  web_learn_more_lower: `En savoir plus`,
  web_login: `Connexion`,
  web_message: `Message`,
  web_mission: `Notre mission, la forêt digitale.`,
  web_next: `Suivant`,
  web_no: `Non`,
  web_password: `Mot de passe`,
  web_payment: `Payement`,
  web_payment_desc: `Merci ! Vos informations ont bien été enregistrées. Vous pouvez maintenant terminer votre commande.`,
  web_payment_failure_list_1: `Allez sur <a href="https://www.timbtrack.com">https://www.timbtrack.com</a> and cliquez sur "<b>Login</b>",`,
  web_payment_failure_list_2: `Entrez vos informations de login et de mot de passe,`,
  web_payment_failure_list_3: `Cliquez sur le lien "<b>Dashboard</b>" dans le popup,`,
  web_payment_failure_list_4: `Cliquez sur "<b>My information</b>",`,
  web_payment_failure_list_5: `Sous la mention "<b>Carte de crédit</b>", vous trouverez une option de modification des informations de paiement.`,
  web_payment_failure_part_1: `Bonjour,<br/><br/>Votre établissement financier a refusé un paiement de votre carte<br/>%s le %s.`,
  web_payment_failure_part_2: `Si ce problème n'est pas résolu, les services Timbtrack en seront affectés.`,
  web_payment_failure_part_3: `Pour mettre à jour vos informations de paiement dès maintenant, procédez comme suit:`,
  web_payment_failure_part_4: `Modifiez ces informations au plus vite pour continuer à bénéficier des services sans interruption.`,
  web_payment_failure_part_5: `L'équipe Timbtrack.`,
  web_payment_failure_title_1: `Tentative de paiement refusée`,
  web_payment_subscription_desc: `Merci, nous avons toutes les informations nécessaires! Vous pouvez maintenant utiliser le bouton ci-dessous pour payer et valider votre abonnement:`,
  web_pending: `En attente`,
  web_phone: `Téléphone`,
  web_phone_number: `Numéro de téléphone`,
  web_platform: `Plateforme`,
  web_platform_sold_out: `Épuisé`,
  web_press: `Presse`,
  web_press_releases: `Revues de presse`,
  web_product: `Produit`,
  web_product_preorder: `Pré-commande`,
  web_product_preordering: `Pré-commander`,
  web_products: `Produits`,
  web_send: `Envoyer`,
  web_sign_up: `S'INSCRIRE`,
  web_software_month: `mois`,
  web_step: `Étape`,
  web_step_1: `Se connecter`,
  web_step_2: `Produits`,
  web_step_3: `Adresses`,
  web_step_4: `Paiement`,
  web_step_5: `Validation`,
  web_step_1_billing: `Étape 1: Adresse de facturation`,
  web_step_1_shipping_address: `Adresse de livraison`,
  web_step_1_billing_address: `Adresse de facturation`,
  web_step_1_same_to_billing_address: `Identique à l'adresse de facturation`,
  web_step_1_email_info: `Pour vous envoyer la facture relative à votre commande`,
  web_step_1_save_address: `Enregistrer l'adresse`,
  web_step_2_payment: `Étape 2: Payement`,
  web_step_2_payment_method: `Mode de paiement`,
  web_step_2_payment_method_credit_card: `Carte de crédit`,
  web_step_2_payment_method_immediate_bank: `Virement bancaire`,
  web_step_2_payment_method_paypal: `Paypal`,
  web_step_2_payment_method_maestro: `Maestro`,
  web_step_2_payment_add_card: `Ajouter une nouvelle carte`,
  web_step_2_payment_validity: `valable jusqu'au`,
  web_step_2_delivery: `Livraison`,
  web_step_2_vat: `TVA`,
  web_step_2_order: `Commande`,
  web_software: `Plateforme`,
  web_step_3_validation_pay_and_validate: `Acheter et valider`,
  web_step_4_access_plateforme: `Aller sur la plateforme Timbtrack`,
  web_step_4_done_thank_you: `Merci beaucoup pour votre commande !`,
  web_step_4_done_email: `Vous recevrez un e-mail de confirmation à l’adresse suivante:`,
  web_street: `Rue et numéro`,
  web_software_order: `Commander`,
  web_software_area: `Calcul de la superficie (ha)`,
  web_software_area_description: `Calcul de la superficie`,
  web_software_cuts: `Identification des coupes à venir`,
  web_software_cuts_description: `Identification des coupes à venir`,
  web_software_lots: `Valorisation de lots en vente`,
  web_software_lots_description: `Valorisation de lots en vente`,
  web_software_stats: `Statistique`,
  web_software_stats_description: `Statistique forestière`,
  web_software_history: `Historique des données`,
  web_software_history_description: `Historique des données`,
  web_software_gps: `Coordonnées GPS`,
  web_software_circumference: `Circonférence`,
  web_software_height: `Hauteur`,
  web_software_conicity: `Conicité`,
  web_software_specie: `Espèces`,
  web_software_diameter: `Diamètre`,
  web_software_quality: `Qualité`,
  web_software_volume: `Volume`,
  web_footer_head_office: `Siège social`,
  web_footer_belgium: `Belgique`,
  web_footer_vat: `TVA: BE 0668.896.459`,
  web_footer_office: `Bureau`,
  web_team: `La Teamtrack`,
  web_team_team: `L'équipe`,
  web_team_ca: `Le conseil d'administration`,
  web_timbtrack_office: `Bureaux de Timbtrack`,
  web_timbtrack_office_address: `Rue de Néverlée 1, 1er étage`,
  web_total: `Total`,
  web_vat: `TVA`,
  web_vat_double_check: `Veuillez revérifier votre numéro de TVA`,
  web_vat_excluded: `HTVA`,
  web_wait: `Veuillez patienter`,
  web_watch_the_movie: `Regardez la video`,
  web_zip: `Code postal`,
  web_technical_information: `Informations techniques`,
  web_inventory: `Inventaire`,
  web_reset_password: `Réinitialiser le mot de passe`,
  web_save: `Sauvegarder`,
  web_terms_and_conditions: `Conditions générales`,
  web_sold_out_product: `Article épuisé`,
  web_sold_out_product_subtitle: `Nous vous contacterons par e-mail quand cet article sera de retour en stock.`,
  web_forest: `Forestier`,
  web_agricultural: `Agricole`,
  web_protected_area: `Zone protégée`,
  web_secure_payment: `Paiement sécurisé`,
  web_delivery_emc: `Livraison dans les 3 mois`,
  web_promo_code: `Entrez votre code promo (facultatif)`,
  web_validate: `Valider`,
  web_jobs: `Jobs`,
  web_full_time: `Temps plein`,
  web_rhisnes: `Rhisnes (Namur) - Belgique`,
  web_job_description: `Description du travail`,
  web_spontaneous_application: `Candidature spontanée`,
  web_spontaneous_application_description: `Timbtrack est en croissance et à la recherche constante de talents prêts à contribuer à une gestion forestière (et des asset ruraux) durable. N'hésitez pas à nous envoyer votre CV.`,
  web_our_events: `Nos événements`,
  web_payment_sign_up: `Je suis nouveau ici`,
  web_management_platform: `Abonnement à la plateforme de gestion`,
  web_measuring_tools: `Outils de mesure`,
  web_validate_plan: `Je valide le plan`,
  web_plan: `Abonnement`,
  web_new_plan: `Nouveau plan`,
  web_do_not_change_subscription: `Je ne désire pas changer d'abonnement`,
  web_do_not_change_tools: `Je ne désire pas d'outils de mesure`,
  web_payment_subscription_platform: `Paiement de l’abonnement pour la plateforme`,
  web_payment_tools: `Paiement pour les produits`,
  web_payment_trunk_mail: `REMARQUE: Si vous n'avez pas reçu cet email, veuillez dans un premier temps, consulter votre dossier "Courrier indésirable".`,
  web_payment_credit_card: `Paiement par carte de crédit et par virement bancaire`,
  web_password_reset_sent: `Un email a été envoyé à l'adresse email suivante`,
  web_below_current_plan: `Vous devez contacter l'un de nos collaborateur pour diminuer de plan.`,
  web_current_plan: `Plan actuel`,
  web_per_month: `Par mois`,
  web_one_year_contract: `Contrat d'un an`,
  web_card_error: `Il y a une erreur avec votre carte de crédit`,
  web_add_credit_card: `Ajouter une carte de crédit`,
  web_google: `Timbtrack | Outils de mesure et plateforme de Gestion Forestière `,
  web_google_moto: `À travers ses outils de mesure d’arbre connectés et sa Plateforme de Gestion Forestière, Timbtrack développe des solutions innovantes et efficaces au service du secteur forestier, permettant un gain de temps de 25 à 70% sur les opérations de cubage, d’inventaire et de gestion.`,
  web_uvp_timbtrack: `Outils de mesure & plateforme de gestion forestière`,
  web_moto_timbtrack: `Innovation, Simplicité, Gain de temps`,
  web_moto2_timbtrack: `Mesurer c'est comprendre sa forêt`,
  web_moto_description_timbtrack: `Timbtrack améliore et simplifie la gestion forestière tout en réduisant de manière significative le temps et les coûts consacrés au mesurage et à la mise en valeur de votre forêt.`,
  web_uvp_emc: `Le mètre forestier connecté`,
  web_moto_emc: `Parfaitement adapté aux propriétaires, gestionnaires et technicien forestier, l'EMC fournit une mesure numérique géo-localisée, rapide et précise des arbres. L’outil permet ainsi d’accélérer et de simplifier  les inventaires forestiers et les opérations de cubage. Lié à la plateforme, l’EMC apporte un gain de temps de mesure et de sécurité des données facilitant les décisions de gestion forestière durable.`,
  web_uvp_tpm: `Tree Parameter Measuring`,
  web_uvp2_tpm: `L’outil de mesure d’arbre le plus avancé du marché`,
  web_moto_tpm: `Développé pour les forestiers professionnels, le TPM est l’outil de mesure d’arbre le plus avancé sur le marché à ce jour. Grâce à des technologies laser, d’imagerie et de géolocalisation de pointe, le TPM relève les paramètres sylvicoles essentiels à une gestion forestière précise et durable (hauteur, circonférence, qualité, position GPS etc.). L’ensemble des données collectées est automatiquement traité par la plateforme de gestion Timbtrack.`,
  web_uvp_platform: `Plateforme`,
  web_uvp2_platform: `Plateforme de gestion forestière et des actifs ruraux`,
  web_moto_platform: `Intuitive esthétique et axée sur l'efficacité, la plateforme vous permet d’importer vos données de terrain en 3 clics, de les analyser automatiquement, et de les classer dans le temps. Les informations nécessaires à la gestion forestière et de vos actifs ruraux n'ont jamais été aussi accessibles et facilement communicables.  Utilisable de manière indépendante, elle est aussi la continuité naturelle de nos outils de mesure.`,
  web_gps_emc: `Relevé d’un point GPS automatique à chaque arbre mesuré`,
  web_circumference_emc: `Mesurez rapidement et précisément la circonférence de vos arbres jusqu'à 6m. Convertible en diamètre sur la plateforme`,
  web_height_emc: `Relevez la hauteur de vos arbres, par défaut dans chaque lot, ou individuellement`,
  web_conicity_emc: `Relevez le taux de conicité (taux de défilement/ …) par défaut dans chaque lot ou individuellement`,
  web_volume_emc: `Volume calculé automatiquement sur la plateforme`,
  web_species_emc: `Relevez l’espèce, sous espèce ou qualité de l’arbre par défaut dans chaque lot ou individuellement `,
  web_numerotation_emc: `Numérotation automatique de chaque arbre mesuré`,
  web_numerotation: `Numérotation`,
  web_seconds_measured_tree_emc: `8 secondes par arbre mesuré`,
  web_time_saving_field_emc: `Gain de temps sur le terrain: 25 à 50%`,
  web_time_saving_office_emc: `Gain de temps au bureau: 100%`,
  web_autonomy_emc: `1 journée de travail`,
  web_warranty_emc: `2 ans`,
  web_weight_emc: `300g`,
  web_moto2_platform: `CONNECTÉ À LA PLATEFORME TIMBTRACK`,
  web_moto3_platform: `La plateforme Timbtrack permet d’avoir une vue globale de l’ensemble de votre forêt. Grâce aux différents modules développés par Timbtrack, la gestion de données forestières se fait de manière automatisée et instantanée, permettant un gain de temps considérable.`,
  web_gps_tpm: `Relevé d’un point GPS automatique à chaque arbre mesuré `,
  web_circumference_tpm: `Mesurez rapidement et précisément le diamètre des arbres, convertible en circonférence sur la plateforme Timbtrack`,
  web_height_tpm: `Mesurez la hauteur précise de vos arbres, en un clic`,
  web_conicity_tpm: `Relevez le taux de conicité (taux de défilement/ ….) en un clic`,
  web_species_tpm: `Relevez L’essence de l’arbre en un clic`,
  web_volume_tpm: `Calcul automatique du volume de l’arbre, sur le terrain`,
  web_quality_tpm: `Relevez la qualité de l'arbre`,
  web_numerotation_tpm: `Numérotation automatique de chaque arbre mesuré`,
  web_seconds_measured_tree_tpm: `3 secondes par arbre mesuré`,
  web_time_saving_field_tpm: `Gain de temps sur le terrain: 60 à 80%`,
  web_time_saving_office_tpm: `Gain de temps au bureau: 100%`,
  web_autonomy_tpm: `1 journée de travail`,
  web_warranty_tpm: `2 ans`,
  web_weight_tpm: `770g`,
  web_safe_about: `À travers ses solutions, Timbtrack s’engage a vous fournir un produit SAFE`,
  web_safe_security_about: `Sureté optimale de collecte et classification de vos données `,
  web_safe_security: `Sécurité`,
  web_safe_audacity_about: `Une innovation permanente donnant accès à des technologies de pointe`,
  web_safe_audacity: `Audace`,
  web_safe_faithful_about: `Des solutions robustes, inscrites sur le long terme`,
  web_safe_faithful: `Fiabilité`,
  web_safe_evolution_about: `Un développement continu, répondant à vos besoins de terrain`,
  web_safe_evolution: `Évolution`,
  web_history_timbtrack: `Revenant d’une expérience entrepreneuriale en Afrique, Quentin d’Huart, fondateur et actionnaire de l’entreprise, décida d’approcher le marché forestier en 2013. C’est en regardant son père prendre des mesures dans la forêt et passer un temps considérable en face d’un ordinateur qu’il comprit qu’il était nécessaire d’apporter des technologies innovantes dans ce secteur traditionnel. En se concertant avec des experts forestiers et des acteurs de la filière bois, il lui est apparu très clairement qu’un appareil sophistiqué pourrait les aider à se concentrer sur leur passion, la forêt, en leur évitant toute la surcharge administrative que ce travail implique. Il rassembla une équipe d’ingénieurs et de designers Belges pour mettre en marche ce nouveau projet. Rapidement, les premiers dessins se transformèrent en un prototype bien réel ; le TPM était né ! Afin de proposer une gamme de produit complète, les équipes Timbtrack ont continué leur développement et ont créé l’EMC, le mètre connecté forestier.  En parallèle, la plateforme de gestion forestière Timbtrack a vu le jour permettant un suivi précis et une gestion durable des actifs ruraux, ce logiciel est la continuité naturel de nos outils. Aujourd’hui Timbtrack se positionne comme le leader belge des outils numérique de gestion forestière.`,
  web_parcel_platform: `Parcellaire`,
  web_area_platform: `Calcul des surfaces (ha)`,
  web_history_platform: `Historique des données`,
  web_batches_sales_platform: `Evaluation des lots à vendre`,
  web_inventory_cubing_platform: `Inventaire et cubage automatisé`,
  web_stats_platform: `Statistiques sylvicoles et financières`,
  web_wood_cut_platform: `Identification des coupes à venir`,
  web_bio_dead_trees_platform: `Arbre à intérêt biologique et mort`,
  web_comment_platform: `Commentaire de gestion`,
  web_modern_interface_platform: `UNE INTERFACE MODERNE`,
  web_modern_interface_moto_platform: `Intuitive, fluide et professionnelle notre plateforme optimise la gestion de vos données forestières. L’ère de l’encodage manuel est révolue !`,
  web_automatic_function_platform: `DES FONCTIONS AUTOMATIQUES`,
  web_automatic_function_moto_platform: `Notre plateforme génère automatiquement vos statistiques d’inventaires. Gain de temps et visibilité assuré sur l’analyse de vos données.`,
  web_simplified_communication_platform: `UNE COMMUNICATION SIMPLIFIÉE`,
  web_simplified_communication_moto_platform: `Grâce aux outils de partage, il n’a jamais été aussi facile de communiquer vos informations aux interlocuteurs concernés.`,
  web_digital_safe_platform: `UN COFFRE FORT NUMÉRIQUE`,
  web_digital_safe_moto_platform: `Hébergées dans notre cloud protégé, accédez à vos données depuis tout navigateur en toute sécurité.`,
  web_or: `ou`,
  web_account_already_have: `Vous possédez déjà un compte avec cette adresse`,
  web_loading_information: `Chargement de vos informations ...`,
  web_yearly: `Annuellement`,
  web_monthly: `Mensuellement`,
  web_save_percentage: `-10%`,
  web_year: `année`,
  web_per_year: `Par an`,
  web_immediate_payment: `Paiement immédiat`,
  web_access_platform: `Accès à la plateforme`,
  web_your_pack: `Votre pack`,
  web_info_sent_mail_bank_transfer: `Les informations vous seront envoyées par email et figurent également à l'étape de validation`,
  web_platform_contract: `accès à la plateforme`,
  web_payment_by_month: `Paiement par mois possible`,
  web_year_2: `an`,
  web_connect_to_platform: `Se connecter à la plateforme`,
  web_already_own_platform: `Je possède déjà la plateforme`,
  web_single_payment: `Paiement à l'unité`,
  web_for_year: `pendant 1 an`,
  web_subscription_platform: `Abonnement à la plateforme`,
  web_buy_1_emc_20_ha: `Vous devez acheter au minimum 1 EMC et 20 ha. Si vous avez déjà la plateforme, veuillez cliquez sur le lien "je possède déjà la plateforme".`,
  web_confirm_buy_agree_1: `En cliquant sur acheter et valider, vous acceptez que :`,
  web_confirm_buy_agree_2: `Pour garantir un service ininterrompu, votre abonnement sera réglé sur des renouvellement de paiement automatique continus de`,
  web_confirm_buy_agree_2_tva: `(plus les taxes applicables)`,
  web_confirm_buy_agree_3: `avec votre prochain paiement dû le`,
  web_confirm_buy_agree_4_year: `Vous nous autorisez à prélever ce montant sur votre carte chaque année`,
  web_confirm_buy_agree_4_month: `Vous nous autorisez à prélever des paiements mensuels sur votre carte pour atteindre le montant total de renouvellement automatique par an.`,
  web_confirm_buy_agree_4_no_vat_europe: `Vous pouvez annuler votre abonnement dans les 15 jours suivant le premier paiement`,
  web_confirm_buy_agree_5: `Vous pouvez désactiver le renouvellement à tout moment à partir de votre profil sur la plateforme (app.timbtrack.com), ou en contactant`,
  web_confirm_buy_agree_6: `Vous pouvez l'annuler depuis votre compte sans nous contacter dans les 14 jours avant la fin de votre contrat. Vous acceptez également nos`,
  web_confirm_buy_agree_7: `et confirmez que vous avez lu et compris notre`,
  web_confirm_buy_title: `Vérification et validation de votre commande`,
  web_transfer_information: `Vous trouverez ci-dessous les informations nécessaires pour le paiement de votre virement. Le processus de livraison commencera dès que nous avons confirmé la bonne réception du paiement.`,
  web_app_baseline: `Gestion forestière et des assets ruraux`,
  web_visors_caregivers: `Visière Intégrale anti-projections !`,
  web_visors_paragraph_1: `Timbtrack a développé une visière conçue pour respecter les procédures d’hygiène mise en place dans le cadre de la crise Covid 19.  Ce masque de protection intégrale du visage a été conçu pour protéger les personnes actives pendant la crise du Covid 19.`,
  web_visors_paragraph_2: `Soutenez le projet, vos dons ont un impact !`,
  web_visors_visit_site: `Visitez le site`,
  web_visors_make_donation: `Faire un don`,
  rules_first_name_required: `Le prénom est requis`,
  rules_last_name_required: `Le nom est obligatoire`,
  rules_phone_number_required: `Le numéro de téléphone n'est pas au format international valide`,
  rules_email_required: `Adresse email invalide`,
  rules_email_required_confirm: `Confirmer que l'adresse e-mail est requise`,
  rules_password_required: `Un nouveau mot de passe est requis`,
  rules_password_not_secure: `Le mot de passe n'est pas suffisamment sécurisé (insérer 8 caractères avec des chiffres et des majuscules)`,
  rules_confirm_new_password: `Confirmer que le nouveau mot de passe est requis`,
  web_mail_request: `Nous vous contacterons par e-mail pour votre demande.`,
  web_sawmill: `Scierie`,
  web_testing: `En test...`,
  web_app_soon:`Mesurer vos arbres avec votre téléphone, c’est bientôt possible !`,
  web_app_soon_store_play:`Bientôt sur l'App Store et Google Play !`,
};
