import flagGb from '../assets/images/flag-gb.svg';
import flagFr from '../assets/images/flag-fr.svg';
import flagPt from '../assets/images/flag-pt.svg';

export const languages = [
  { text: 'English', value: 'en',},
  { text: 'Français', value: 'fr',},
  { text: 'Nederlands', value: 'nl',},
  { text: 'Português', value: 'pt',},
  { text: 'Deutsche', value: 'de',},
];

export const latin = {
  text: 'Latin',
  value: 'latin',
  icon: null,
};
