import { http, UPS_SHIPPING } from '../../services';

const UPS_SHIPPING_LOADING = 'UPS_SHIPPING_LOADING';
const UPS_SHIPPING_UPDATE = 'UPS_SHIPPING_UPDATE';

const initialState = () => ({
  shippingLoading: false,
  shippingRate: null,
});

const state = {
  ...initialState(),
  initialState,
};

const actions = {
  getShippingRate: async ({ commit }, parameters) => {
    commit(UPS_SHIPPING_LOADING, true);
    try {
      const { data: { price } } = await http.get(UPS_SHIPPING.GET, {params: parameters});
      commit(UPS_SHIPPING_UPDATE, parseFloat(price));
    } catch (e) {
      throw e;
    } finally {
      commit(UPS_SHIPPING_LOADING, false);
    }
  },
};

const mutations = {
  [UPS_SHIPPING_UPDATE](state, deliveryPrice) {
    state.shippingRate = deliveryPrice;
  },
  [UPS_SHIPPING_LOADING](state, loading) {
    state.shippingLoading = loading;
  },
};

export default {
  state,
  actions,
  mutations,
};
