import { languages } from '../constants';

const LANGUAGE_UPDATE = 'LANGUAGE_UPDATE';

const state = {
  language: languages[0],
};

const actions = {
  switchLanguage: ({ commit }, language) => {
    localStorage.setItem('language', language.value);
    commit(LANGUAGE_UPDATE, language);
  },
};

const mutations = {
  [LANGUAGE_UPDATE](_, language) {
    state.language = language;
  },
};

export default {
  state,
  actions,
  mutations,
};
