import enMessage from './en/message';
import frMessage from './fr/message';
import ptMessage from './pt/message';
import nlMessage from './nl/message';
import deMessage from './de/message';

import enCountries from './en/countries';
import frCountries from './fr/countries';
import ptCountries from './pt/countries';
import nlCountries from './nl/countries';
import deCountries from './de/countries';

export const en = {
  message: enMessage,
  country: enCountries,
};

export const fr = {
  message: frMessage,
  country: frCountries,
};

export const pt = {
  message: ptMessage,
  country: ptCountries,
};

export const nl = {
  message: nlMessage,
  country: nlCountries,
};

export const de = {
  message: deMessage,
  country: deCountries,
};
