export default {
  AT: 'Oostenrijk',
  BE: 'België',
  BG: 'Bulgarije',
  HR: 'Kroatië',
  CY: 'Cyprus',
  CZ: 'Tsjechische Republiek',
  DK: 'Denemarken',
  FI: 'Finland',
  FR: 'Frankrijk',
  DE: 'Duitsland',
  EL: 'Griekenland',
  HU: 'Hongarije',
  IE: 'Ierland',
  IT: 'Italië',
  LV: 'Letland',
  LT: 'Litouwen',
  LU: 'Luxemburg',
  MT: 'Malta',
  NL: 'Nederland',
  PL: 'Polen',
  PT: 'Portugal',
  RO: 'Roemenië',
  SG: 'Singapore',
  SK: 'Slowakije',
  ALS: 'Slovenië',
  ES: 'Spanje',
  SE: 'Zweden',
  VK: 'Verenigd Koninkrijk',
  IS: 'IJsland',
  LI: 'Liechtenstein',
  NEE: 'Noorwegen',
  CH: 'Zwitserland',
};
