const UI_UPDATE_LOGIN_DIALOG = 'UI_UPDATE_LOGIN_DIALOG';
const UI_UPDATE_SIGN_UP_DIALOG = 'UI_UPDATE_SIGN_UP_DIALOG';
const UI_UPDATE_DIRECTION_DIALOG = 'UI_UPDATE_DIRECTION_DIALOG';
const UI_UPDATE_LOGOUT_DIALOG = 'UI_UPDATE_LOGOUT_DIALOG';
const UI_UPDATE_VIDEO_DIALOG = 'UI_UPDATE_VIDEO_DIALOG';

const state = {
  loginDialogVisibility: false,
  signUpDialogVisibility: false,
  directionDialogVisibility: false,
  logoutDialogVisibility: false,
  videoDialogVisibility: false,

  logoutForInactivity: false, // TODO: Update this if logged out for inactivity
};

const actions = {
  updateLoginDialog: ({ commit }, visibility) => {
    commit(UI_UPDATE_LOGIN_DIALOG, visibility);
  },
  updateSignUpDialog: ({ commit }, visibility) => {
    commit(UI_UPDATE_SIGN_UP_DIALOG, visibility);
  },
  updateDirectionDialog: ({ commit }, visibility) => {
    commit(UI_UPDATE_DIRECTION_DIALOG, visibility);
  },
  updateLogoutDialog: ({ commit }, visibility) => {
    commit(UI_UPDATE_LOGOUT_DIALOG, visibility);
  },
  updateVideoDialog: ({ commit }, visibility) => {
    commit(UI_UPDATE_VIDEO_DIALOG, visibility);
  },
};

const mutations = {
  [UI_UPDATE_LOGIN_DIALOG](_, visibility) {
    state.loginDialogVisibility = visibility;
  },
  [UI_UPDATE_SIGN_UP_DIALOG](_, visibility) {
    state.signUpDialogVisibility = visibility;
  },
  [UI_UPDATE_DIRECTION_DIALOG](_, visibility) {
    state.directionDialogVisibility = visibility;
  },
  [UI_UPDATE_LOGOUT_DIALOG](_, visibility) {
    state.logoutDialogVisibility = visibility;
  },
  [UI_UPDATE_VIDEO_DIALOG](_, visibility) {
    state.videoDialogVisibility = visibility;
  },
};

export default {
  state,
  actions,
  mutations,
};
