import { http, CREDENTIALS } from '../../services';

const CREDENTIALS_LOGIN_LOADING = 'CREDENTIALS_LOGIN_LOADING';
const CREDENTIALS_LOGOUT_LOADING = 'CREDENTIALS_LOGOUT_LOADING';
const CREDENTIALS_ASK_RESET_PASSWORD_LOADING = 'CREDENTIALS_ASK_RESET_PASSWORD_LOADING';
const CREDENTIALS_RESET_PASSWORD_LOADING = 'CREDENTIALS_RESET_PASSWORD_LOADING';
const CREDENTIALS_FORGOT_PASSWORD_LOADING = 'CREDENTIALS_FORGOT_PASSWORD_LOADING';

const CREDENTIALS_CHECKING_EXISTING_MAIL = 'CREDENTIALS_CHECKING_EXISTING_MAIL';

const PROFILE_TOKEN_PERSIST = 'PROFILE_TOKEN_PERSIST';
const PROFILE_TOKEN_CLEAR = 'PROFILE_TOKEN_CLEAR';

const initialState = () => ({
  loginLoading: false,
  logoutLoading: false,
  askResetPasswordLoading: false,
  resetPasswordLoading: false,
  forgotPasswordLoading: false,
  checkingExistingMail: false,
});

const state = {
  ...initialState(),
  initialState,
};

const actions = {
  login: async ({ commit }, { email_address, password }) => {
    commit(CREDENTIALS_LOGIN_LOADING, true);
    try {
      const { data: { token } } = await http.post(CREDENTIALS.LOGIN, {
        email_address,
        password,
      });
      localStorage.setItem('token', token);
    } catch ({ response: { data: { error } } }) {
      throw error;
    } finally {
      commit(CREDENTIALS_LOGIN_LOADING, false);
    }
  },
  forgotPassword: async ({ commit }, email) => {
    commit(CREDENTIALS_FORGOT_PASSWORD_LOADING, true);
    try {
      await http.post(CREDENTIALS.FORGOT, { email_address: email, vitrine: true });
    } catch (error) {
      throw error;
    } finally {
      commit(CREDENTIALS_FORGOT_PASSWORD_LOADING, false);
    }
  },
  askResetPassword: async ({ commit }, email) => {
    // TODO: Reset password flow
    commit(CREDENTIALS_ASK_RESET_PASSWORD_LOADING, true);
    try {
      await http.post(CREDENTIALS.ASK_RESET, { email_address: email });
    } catch (error) {
      throw error;
    } finally {
      commit(CREDENTIALS_ASK_RESET_PASSWORD_LOADING, false);
    }
  },
  resetPassword: async ({ commit }, { token, password }) => {
    commit(CREDENTIALS_RESET_PASSWORD_LOADING, true);
    try {
      await http.post(CREDENTIALS.RESET, { token, password });
    } catch (error) {
      throw error;
    } finally {
      commit(CREDENTIALS_RESET_PASSWORD_LOADING, false);
    }
  },
  logout: async ({ commit }) => {
    commit(CREDENTIALS_LOGOUT_LOADING, true);
    try {
      await http.post(CREDENTIALS.LOGOUT);
      commit(PROFILE_TOKEN_CLEAR);
    } catch ({ response: { data: { error } } }) {
      throw error;
    } finally {
      commit(CREDENTIALS_LOGOUT_LOADING, false);
    }
  },
  checkingExistingMail: async ({ commit }, email_address) => {
    commit(CREDENTIALS_CHECKING_EXISTING_MAIL, true);
    try {
      const { data } = await http.post(CREDENTIALS.CHECK, { email_address });
      return data;
    } catch (e) {
      throw e;
    } finally {
      commit(CREDENTIALS_CHECKING_EXISTING_MAIL, false);
    }
  },
};

const mutations = {
  [CREDENTIALS_LOGIN_LOADING](state, loading) {
    state.loginLoading = loading;
  },
  [CREDENTIALS_LOGOUT_LOADING](state, loading) {
    state.logoutLoading = loading;
  },
  [CREDENTIALS_ASK_RESET_PASSWORD_LOADING](state, loading) {
    state.askResetPasswordLoading = loading;
  },
  [CREDENTIALS_RESET_PASSWORD_LOADING](state, loading) {
    state.resetPasswordLoading = loading;
  },
  [PROFILE_TOKEN_PERSIST]({}, token) {
    localStorage.setItem('token', token);
  },
  [PROFILE_TOKEN_CLEAR]({}) {
    localStorage.removeItem('token');
  },
  [CREDENTIALS_FORGOT_PASSWORD_LOADING](state, loading) {
    state.forgotPasswordLoading = loading;
  },
  [CREDENTIALS_CHECKING_EXISTING_MAIL](state, checking) {
    state.checkingExistingMail = checking;
  },
};

export default {
  state,
  actions,
  mutations,
}
