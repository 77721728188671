/* eslint quotes: ["error", "backtick"] */

export default {
  web_available: `Available`,
  web_exhausted: `Exhausted`,
  web_accept_terms_and_privacy_1: `I accept the`,
  web_accept_terms_and_privacy_2: `Terms of service`,
  web_accept_terms_and_privacy_3: `and the`,
  web_accept_terms_and_privacy_4: `Privacy Policy`,
  web_about: `About`,
  web_already_have_account: `You already have an account?`,
  web_cancel: `Cancel`,
  web_circumference: `Circumference`,
  web_city: `City`,
  web_comment: `Comment`,
  web_company_name: `Company name`,
  web_optional: ` (optional)`,
  web_confirm: `Confirm`,
  web_contact: `Contact`,
  web_contact_success: `We have successfully received your contact request and will get back to you shortly. Thank you for showing interest in our products!`,
  web_country: `Country`,
  web_delete: `Delete`,
  web_email: `E-mail`,
  web_emc_in_practice: `In practice`,
  web_first_name: `First name`,
  web_forgot_password: `Forgot password?`,
  web_form_required_email_confirm: `Confirm your email address`,
  web_form_required_field: `This field is required`,
  web_gdpr_signup_information: `By creating an account, you acknowledge that Timbtrack gives you access to a version of its digital services in the management and classification of forest data.<br/></br>These services are offered by I.B.R. S.A. who owns the brand "Timbtrack".<br/></br>In order to operate these services properly, Timbtrack needs to use your personal data to create your access, make it secure and create links between users in the Timbtrack community.<br/></br>Your data will never be sold to third parties without your explicit consent. We guarantee that without your permission, this sensitive information will never be used for other reasons than to run Timbtrack's digital services.<br/></br>The personal information we collect is as follows: <b>last name</b>, <b>first name</b>, <b>e-mail address</b>, <b>telephone number</b> and your <b>property address(es)</b>.<br/></br>When you give your consent, you receive a personal and protected access. It is accessible via the "Login" on the web page www.timbtrack.com with your username and password, known only to you. You can at any time consult your personal data and / or edit the deletion of data as part of a contract is governed by obligations. To be held by IBR S.A. and use.<br/>Warning: we recommend that you keep this access confidential.<br/></br>We are at your disposal for any questions you may have at the e-mail address info@timbtrack.com.<br/></br>The Timbtrack team <br/>`,
  web_gdpr_title: `Regarding your personal information`,
  web_get_in_touch: `Get in touch`,
  web_height: `Height`,
  web_history: `The beginning...`,
  web_home: `Home`,
  web_i_agree: `I agree`,
  web_included: `Included`,
  web_information: `Information`,
  web_last_name: `Last name`,
  web_learn_more: `LEARN MORE`,
  web_learn_more_lower: `Learn more`,
  web_login: `Login`,
  web_message: `Message`,
  web_mission: `Our mission, the digital forest.`,
  web_next: `Next`,
  web_no: `No`,
  web_password: `Password`,
  web_payment: `Payment`,
  web_payment_desc: `Thank you! All necessary information has been sent. You can now complete your order.`,
  web_payment_failure_list_1: `Go to <a href="https://www.timbtrack.com">https://www.timbtrack.com</a> and click on "<b>Login</b>",`,
  web_payment_failure_list_2: `Type in your user name and password,`,
  web_payment_failure_list_3: `Click on the "<b>Dashboard</b>" link in the popup`,
  web_payment_failure_list_4: `Click on "<b>My information</b>"`,
  web_payment_failure_list_5: `Under the "<b>Credit card</b>" title, you will find an option to modify your payment method.`,
  web_payment_failure_part_1: `Hello,<br/><br/>Your bank has denied a payment coming from your card below:<br/>%s on the %s.`,
  web_payment_failure_part_2: `If this problem persists, the Timbtrack services will be affected.`,
  web_payment_failure_part_3: `To update your payment information, you can proceed as such:`,
  web_payment_failure_part_4: `Please modify this information as soon as you can to keep on enjoying the Timbtrack services without interruption.`,
  web_payment_failure_part_5: `The Timbtrack team.`,
  web_payment_failure_part_6: `<b>Please do not reply to this e-mail!</b><br/>Should you have any questions about your invoice, please contact us at info@timbtrack.com with your billing number as reference.<br/>`,
  web_payment_failure_title_1: `Payment rejected`,
  web_payment_subscription_desc: `Thank you, we have all the information we need! You can now use the button below to pay and finish your subscription:`,
  web_pending: `Pending`,
  web_phone: `Phone`,
  web_phone_number: `Phone number`,
  web_platform: `Platform`,
  web_platform_sold_out: `Exhausted`,
  web_press: `Press`,
  web_press_releases: `Press releases`,
  web_product: `Product`,
  web_product_preorder: `Pre-order`,
  web_product_preordering: `Pre-order`,
  web_products: `Products`,
  web_send: `Send`,
  web_sign_up: `SIGN UP`,
  web_software_month: `month`,
  web_step: `Step`,
  web_step_1: `Login`,
  web_step_2: `Products`,
  web_step_3: `Addresses`,
  web_step_4: `Payment`,
  web_step_5: `Validation`,
  web_step_1_billing: `Step 1: Billing address`,
  web_step_1_shipping_address: `Shipping address`,
  web_step_1_billing_address: `Billing address`,
  web_step_1_same_to_billing_address: `Same as shipping address`,
  web_step_1_email_info: `To send you the invoice for your order`,
  web_step_1_save_address: `Save address`,
  web_step_2_payment: `Step 2: Payment`,
  web_step_2_payment_method: `Payment method`,
  web_step_2_payment_method_credit_card: `Credit card`,
  web_step_2_payment_method_immediate_bank: `Bank transfer`,
  web_step_2_payment_method_paypal: `Paypal`,
  web_step_2_payment_method_maestro: `Maestro`,
  web_step_2_payment_add_card: `Add a new card`,
  web_step_2_payment_validity: `valid until`,
  web_step_2_delivery: `Delivery`,
  web_step_2_vat: `VAT`,
  web_step_2_order: `Order`,
  web_software: `Platform`,
  web_step_3_validation_pay_and_validate: `Buy and validate`,
  web_step_4_access_plateforme: `Go to the Timbtrack platform`,
  web_step_4_done_thank_you: `Thank you very much for your order!`,
  web_step_4_done_email: `You will receive a confirmation e-mail at the following address:`,
  web_street: `Street and number`,
  web_software_order: `Order`,
  web_software_area: `Calculation of the area (ha)`,
  web_software_area_description: `Calculation of the area`,
  web_software_cuts: `Identification of upcoming cuts`,
  web_software_cuts_description: `Identification of upcoming cuts`,
  web_software_lots: `Valuation of lots for sale`,
  web_software_lots_description: `Valuation of lots for sale`,
  web_software_stats: `Statistics`,
  web_software_stats_description: `Forestry statistics`,
  web_software_history: `Data History`,
  web_software_history_description: `Data History`,
  web_software_gps: `Coordinated GPS`,
  web_software_circumference: `Circumference`,
  web_software_height: `Height`,
  web_software_conicity: `Conicity`,
  web_software_specie: `Species`,
  web_software_diameter: `Diameter`,
  web_software_quality: `Quality`,
  web_software_volume: `Volume`,
  web_footer_head_office: `The head office`,
  web_footer_belgium: `Belgium`,
  web_footer_vat: `VAT: BE 0668.896.459`,
  web_footer_office: `Office`,
  web_team: `The Timbtrack`,
  web_team_team: `The team`,
  web_team_ca: `Board of directors`,
  web_timbtrack_office: `Timbtrack Office`,
  web_timbtrack_office_address: `Rue de Néverlée 1, 1st floor`,
  web_total: `Total`,
  web_vat: `VAT`,
  web_vat_double_check: `Please double check your VAT Number`,
  web_vat_excluded: `excl. VAT`,
  web_wait: `Please wait`,
  web_watch_the_movie: `Watch the movie`,
  web_zip: `Postcode`,
  web_technical_information: `Technical information`,
  web_inventory: `Inventory`,
  web_reset_password: `Reset password`,
  web_save: `Save`,
  web_terms_and_conditions: `Terms and conditions`,
  web_sold_out_product: `Product out of stock`,
  web_sold_out_product_subtitle: `We will contact you by email when this item will be back in stock.`,
  web_forest: `Forestry`,
  web_agricultural: `Agricultural`,
  web_protected_area: `Protected area`,
  web_secure_payment: `Secure payment`,
  web_delivery_emc: `Delivery within 3 months`,
  web_promo_code: `Enter your promo code (optional)`,
  web_validate: `Validate`,
  web_jobs: `Jobs`,
  web_full_time: `Full-time`,
  web_rhisnes: `Rhisnes (Namur) - Belgium`,
  web_job_description: `Job description`,
  web_spontaneous_application: `Spontaneous application`,
  web_spontaneous_application_description: ` Timbtrack is growing and constantly seeking talent ready to contribute to sustainable forest management (and rural assets). Do not hesitate to send us your resume.`,
  web_our_events: `Our events`,
  web_payment_sign_up: `I am new here`,
  web_management_platform: `Subscription to the management platform`,
  web_measuring_tools: `Measuring tools`,
  web_validate_plan: `I validate the plan`,
  web_plan: `Subscription`,
  web_new_plan: `New plan`,
  web_do_not_change_subscription: `I do not want to change my subscription`,
  web_do_not_change_tools: `I do not want measuring tools`,
  web_payment_subscription_platform: `Payment of the subscription for the platform`,
  web_payment_tools: `Payment for the products`,
  web_payment_trunk_mail: `NOTE: If you have not received this email, please first check your Junk Mail folder.`,
  web_payment_credit_card: `Payment by credit card and bank transfer`,
  web_password_reset_sent: `An email has been sent to the following email address`,
  web_below_current_plan: `You must contact one of our collaborators to reduce the plan.`,
  web_current_plan: `Current plan`,
  web_per_month: `Per month`,
  web_one_year_contract: `One year contract`,
  web_card_error: `There is an error with your credit card`,
  web_add_credit_card: `Add a credit card`,
  web_google: `Timbtrack | Measurement Tools and Forest Management Platform`,
  web_google_moto: `Through its connected tree measuring tools and Forest Management Platform, Timbtrack develops innovative and effective solutions for the forestry sector, saving  25 to 70% of the time of cubing, inventory and management operations.`,
  web_uvp_timbtrack: `Measurement tools & forest management platform`,
  web_moto_timbtrack: `Innovative, Simple, Time Saving`,
  web_moto2_timbtrack: `Measuring is understanding your forest`,
  web_moto_description_timbtrack: `Timbtrack improve and simplify the management of your forest while drastically reducing time and cost devoted to the measurement and valuation of your forest.`,
  web_uvp_emc: `The connected forest meter`,
  web_moto_emc: `Perfectly suited to forest owners, managers and technicians, the EMC provides a geo-localized, fast and accurate digital tree measurement . The tool thus makes possible to accelerate and simplify forest inventories and cubing operations. Linked to the platform, the EMC save times and improve data security, facilitating sustainable forest management decisions.`,
  web_uvp_tpm: `Tree Parameter Measuring`,
  web_uvp2_tpm: `The most advanced tree measurement tool on the market`,
  web_moto_tpm: `Developed for professional foresters, the TPM is the most advanced tree measurement tool on the market so fare. Thanks to laser, imaging and geolocation technologies, the TPM records the ensential silvicultural parameters to a precise and sustainable forest management (height, circumference, quality, GPS position etc.). All collected data is automatically processed through Timbtrack management platform.`,
  web_uvp_platform: `Platform`,
  web_uvp2_platform: `Forest management platform and rural assets`,
  web_moto_platform: `Intuitive, aesthetic and efficiency-oriented, Timbtrack's platform allows you to import your field data in 3 clicks, analyze them automatically, and classify them in time.The information needed for forest management and your rural assets have never been so accessible and easily communicated. Usable independently, it is also the natural extension of our measurement tools.`,
  web_gps_emc: `Automatic GPS position at each measured tree`,
  web_circumference_emc: `Quick and accurate measure of the circumference of your trees up to 6m. Convertible in diameter on the platform `,
  web_height_emc: `Record the height of your trees, per batch, or individually`,
  web_conicity_emc: `Record the default taper rate (scroll rate / ...) per batch or individually`,
  web_volume_emc: `Automatically calculated Volume on the platform`,
  web_species_emc: `Record the default species, subspecies or quality per batch or individually "`,
  web_numerotation_emc: `Automatic numbering of each measured tree`,
  web_numerotation: `Numbering`,
  web_seconds_measured_tree_emc: `8 seconds per measured tree`,
  web_time_saving_field_emc: `Time saving on the field: 25 to 50%`,
  web_time_saving_office_emc: `Time saving at the office: 100%`,
  web_autonomy_emc: `1 working day`,
  web_warranty_emc: `2 years warranty`,
  web_weight_emc: `300g`,
  web_moto2_platform: `CONNECTED TO THE TIMBTRACK PLATFORM`,
  web_moto3_platform: `The Timbtrack platform allows you to have a global view of your entire forest. Thanks to the different modules developed by Timbtrack, forest data management is automated and instantaneous, saving a considerable amount of time.`,
  web_gps_tpm: `Automatic GPS position at each measured tree`,
  web_circumference_tpm: `Quick and precise mesure the diameter of the trees, convertible in circumference on the Timbtrack platform`,
  web_height_tpm: `Measure the precise height of your trees, in one click`,
  web_conicity_tpm: `Record the conicity rate (taper / ....) In one click`,
  web_species_tpm: `Record the conicity rate (taper / ....) In one click`,
  web_volume_tpm: `Automatic calculation of the volume of the tree, in the field`,
  web_quality_tpm: `Record the quality of the tree`,
  web_numerotation_tpm: `Automatic numbering of each measured tree`,
  web_seconds_measured_tree_tpm: `3 seconds per measured tree`,
  web_time_saving_field_tpm: `Time saving on the field: 60 to 80%`,
  web_time_saving_office_tpm: `Time saving at the office: 100%`,
  web_autonomy_tpm: `1 working day`,
  web_warranty_tpm: `2 years`,
  web_weight_tpm: `770g`,
  web_safe_about: `Through its solutions, Timbtrack is committed providing you with a SAFE product`,
  web_safe_security_about: `Optimal security of collection and classification of your data`,
  web_safe_security: `Security`,
  web_safe_audacity_about: `A permanent innovation giving access to advanced technologies`,
  web_safe_audacity: `Audacity`,
  web_safe_faithful_about: `Robust and long-term solutions`,
  web_safe_faithful: `Faithful`,
  web_safe_evolution_about: `Continuous development, meeting your fields needs`,
  web_safe_evolution: `Evolution`,
  web_history_timbtrack: `Returning from an entrepreneurial experience in Africa, Quentin d'Huart, founder and shareholder of the company, decided to invest himself in the forest market in 2013. It is watching his father traditionnaly measuring trees  and spending time in front of a computer that he understood the necessity to bring innovative technologies in this traditional sector. In consultation with forestry experts and actors of the wood industry it became clear to him that a sophisticated device could help them focus on their passion, the forest,  avoiding all the administrative overhead that this work involves. He gathered a team of Belgian engineers and designers to start this new project. Soon, the first drawings became a real prototype; the TPM was born! In order to offer a complete range of products, the Timbtrack team continued their development and created the EMC, the connected forest meter. In parallel, the forest management platform was born, allowing  precise monitoring and sustainable management of rural assets. This software is the natural extension of our tools. Today Timbtrack positions itself as the Belgian leader of forest management digital tools.`,
  web_parcel_platform: `Parcelle plottinng`,
  web_area_platform: `Surfaces calculation (ha)`,
  web_history_platform: `Data history`,
  web_batches_sales_platform: `Valuation of wood batches for sale`,
  web_inventory_cubing_platform: `Automatic inventory and cubing`,
  web_stats_platform: `Silvicultural and financial statistics`,
  web_wood_cut_platform: `Identification of future wood cuts`,
  web_bio_dead_trees_platform: `Biological interest and dead trees`,
  web_comment_platform: `management comment`,
  web_modern_interface_platform: `A Modern Interface`,
  web_modern_interface_moto_platform: `Intuitive, userfriendly and professional, our platform omptimize and simplify  the management of forestry data.`,
  web_automatic_function_platform: `Automatic Functions`,
  web_automatic_function_moto_platform: `All the statistique are automatically generated. Save time and improve the readability of your data analysis`,
  web_simplified_communication_platform: `A SIMPLIFIED COMMUNICATION`,
  web_simplified_communication_moto_platform: `Thanks to the possibility to share inforamtion, the communiation with all the conerned players have never be so easy`,
  web_digital_safe_platform: `A Digital Safe Box`,
  web_digital_safe_moto_platform: `Hosted in our safe cloud, you can safely  access your data from everywhere `,
  web_or: `or`,
  web_account_already_have: `You already have an account with this address`,
  web_loading_information: `Loading your information ...`,
  web_yearly: `Yearly`,
  web_monthly: `Monthly`,
  web_save_percentage: `-10%`,
  web_year: `year`,
  web_per_year: `Per year`,
  web_immediate_payment: `immediate payment`,
  web_access_platform: `Access to the platform`,
  web_your_pack: `Your pack`,
  web_info_sent_mail_bank_transfer: `The information will be sent to you by email and will also appear in the validation step`,
  web_platform_contract: `access to the platform`,
  web_payment_by_month: `Payment by month possible`,
  web_year_2: `year`,
  web_connect_to_platform: `Connect to the platform`,
  web_already_own_platform: `I already own the platform`,
  web_single_payment: `Single payment`,
  web_for_year: `for 1 year`,
  web_subscription_platform: `Subscription to the platform`,
  web_buy_1_emc_20_ha: `You must buy at least 1 EMC and 20 ha. If you already have the platform, please click on the link "I already own the platform".`,
  web_confirm_buy_agree_1: `By clicking on buy and validate, you agree that:`,
  web_confirm_buy_agree_2: `To ensure uninterrupted service, your subscription will be settled on the total automatic payment renewal of`,
  web_confirm_buy_agree_2_tva: `(plus applicable taxes)`,
  web_confirm_buy_agree_3: `with your next payment due on`,
  web_confirm_buy_agree_4_year: `You authorize us to take this amount from your card each year.`,
  web_confirm_buy_agree_4_month: `You authorize us to take monthly payments from your card to reach the total auto-renewal amount per year.`,
  web_confirm_buy_agree_4_no_vat_europe: `You can cancel your subscription within 15 days from the first payment`,
  web_confirm_buy_agree_5: `You can deactivate the renewal at any time from your profile on the platform (app.timbtrack.com), or by contacting`,
  web_confirm_buy_agree_6: `You can cancel it from your account without contacting us within 14 days prior to the end of your contract. You also agree to our`,
  web_confirm_buy_agree_7: `and confirm that you have read and understood our`,
  web_confirm_buy_title: `Verification and validation of your order`,
  web_transfer_information: `You will find below the information necessary for the payment of your transfer. The delivery process will begin as soon as we have confirmed receipt of payment.`,
  web_app_baseline: `Forest Management and Rural Assets`,
  web_visors_caregivers: `Anti-splash full visor`,
  web_visors_paragraph_1: `Timbtrack has developed a visor designed to comply with the hygiene procedures implemented in the context of the Covid 19 crisis. This full face protection mask was designed to protect people active during the Covid 19 crisis.`,
  web_visors_paragraph_2: `Support the project, your donations have an impact!`,
  web_visors_visit_site: `Visit the site`,
  web_visors_make_donation: `Make a donation`,
  rules_first_name_required: `First name is required`,
  rules_last_name_required: `Last name is required`,
  rules_phone_number_required: `Phone number is not in the valid international format`,
  rules_email_required: `Email address is invalid`,
  rules_email_required_confirm: `Confirm email address is required`,
  rules_password_dont_match: `Passwords do not match`,
  rules_new_password_dont_match: `Passwords do not match`,
  rules_password_required: `New password is required`,
  rules_password_not_secure: `Password is not secure enough (insert 8 characters with numbers and capital letters)`,
  rules_confirm_new_password: `Confirm new password is required`,
  web_mail_request: `We will contact you by e-mail with your request.`,
  web_sawmill: `Sawmill`,
  web_testing: `In testing...`,
  web_app_soon:`Measure your trees with your phone, it will soon be possible!`,
  web_app_soon_store_play:`Coming soon to the App Store and Google Play!`,
};
