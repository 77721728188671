import Vue from 'vue';
import Vuex from 'vuex';

import uiVitrine from './modules/uiVitrine';

import profile from './modules/profile';
import subscription from './modules/subscription';
import credentials from './modules/credentials';
import language from './modules/language';
import orders from './modules/orders';
import vatChecker from './modules/vatChecker';
import upsShipping from './modules/upsShipping';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    uiVitrine,
    profile,
    subscription,
    credentials,
    language,
    orders,
    vatChecker,
    upsShipping,
  },
});

export default store;
