import { http, ORDERS, PRICINGS, SUBSCRIPTION } from '../../services';

const FETCHED_PRICES = 'FETCHED_PRICES';
const ORDERS_UPDATE = 'ORDERS_UPDATE';
const ORDERS_LOADING = 'ORDERS_LOADING';
const ORDERS_NEW_ORDER_UPDATE = 'ORDERS_NEW_ORDER_UPDATE';
const ORDERS_NEW_ORDER_LOADING = 'ORDERS_NEW_ORDER_LOADING';

const initialState = () => ({
  prices: null,
  orders: [],
  orderLoading: false,
  newOrder: null,
  newOrderLoading: false,
});

const state = {
  ...initialState(),
  initialState,
};

const actions = {
  fetchPrices: async ({ commit }, params) => {
    try {
      const { data } = await http.post(PRICINGS.POST, params);
      commit(FETCHED_PRICES, data);
      return data;
    } catch (e) {
      throw e;
    }
  },
  fetchOrders: async ({ commit }) => {
    try {
      commit(ORDERS_LOADING, true);
      const { data: { orders } } = await http.get(ORDERS.GET);
      return orders;
    } catch (e) {
      throw e;
    }
  },
  setNewOrder: ({ commit }, order) => {
    commit(ORDERS_NEW_ORDER_UPDATE, order);
  },
  validateDiscount: async (_,  { code, emcCount }) => {
    try {
      const { data: { discount } } = await http.get(`${ORDERS.DISCOUNT_VALIDATE}/${code}`, { params: { emcCount } });
      return discount;
    } catch (e) {
      throw e;
    }
  },
  createNewOrder: async ({ commit, state }, orderDb) => {
    commit(ORDERS_NEW_ORDER_LOADING, true);
    try {
      const { data: { id } } = await http.post(ORDERS.POST, orderDb);
      const order = state.newOrder;
      order.id = id;
      commit(ORDERS_NEW_ORDER_UPDATE, order);
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_NEW_ORDER_LOADING, false);
    }
  },
  stripeSetupIntent: async ({ commit, state }, customerInfo) => {
    return await http.post(ORDERS.SETUP_INTENT, customerInfo);
  },
  stripeSetupPaymentMethod: async ({ commit, state }, customerInfo) => {
    http.post(ORDERS.SETUP_PAYMENT_METHOD, customerInfo);
    const order = state.newOrder;
    order.paymentMethod = customerInfo.payment_method;
    commit(ORDERS_NEW_ORDER_UPDATE, order);
  },
  getStripeCustomerCards: async (userId) => {
    try {
      const { data } = await http.get(ORDERS.GET_METHODS, { user_id: userId });
      return data;
    } catch (e) {
      throw e;
    }
  },
  payOrder: async ({ commit, state }) => {
    commit(ORDERS_NEW_ORDER_LOADING, true);
    try {
      const { data: { token, stripePaymentIntentSecret, stripeAmountPaid } } = await http.post(ORDERS.PAY, {
        id: state.newOrder.id,
        stripe_payment_method: state.newOrder.paymentMethod,
        payment_method: state.newOrder.getPaymentMethod(),
        payment_token: state.newOrder.getPaymentToken(),
        saved_payment_method: state.newOrder.getPayment().paymentMethodId
      });
      const order = state.newOrder;
      order.token = token;
      order.stripePaymentIntentSecret = stripePaymentIntentSecret;
      order.stripeAmountPaid = stripeAmountPaid;
      commit(ORDERS_NEW_ORDER_UPDATE, order);
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_NEW_ORDER_LOADING, false);
    }
  },
  upgradePlan: async ({ commit }, new_ha_limit) => {
    commit(ORDERS_NEW_ORDER_LOADING, true);
    try {
      await http.post(SUBSCRIPTION.UPGRADE, { new_ha_limit });
      return 'upgraded';
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_NEW_ORDER_LOADING, false);
    }
  },
  validateOrder: async ({ commit, state }, orderId) => {
    commit(ORDERS_NEW_ORDER_LOADING, true);
    await http.post(ORDERS.VALIDATE, {
      id: orderId,
      token: state.newOrder.token,
      stripe_payment_method: state.newOrder.paymentMethod
    });
    commit(ORDERS_NEW_ORDER_LOADING, false);
  }
};

const mutations = {
  [FETCHED_PRICES](state, prices) {
    state.prices = prices;
  },
  [ORDERS_UPDATE](state, orders) {
    state.orders = orders;
  },
  [ORDERS_LOADING](state, loadinh) {
    state.orderLoading = loadinh;
  },
  [ORDERS_NEW_ORDER_UPDATE](state, order) {
    state.newOrder = order;
  },
  [ORDERS_NEW_ORDER_LOADING](state, loading) {
    state.newOrderLoading = loading;
  },
};

export default {
  state,
  actions,
  mutations,
};
